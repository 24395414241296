.home-layout{ background: transparent; font-size: 14px; font-weight: 400; color: #2c2c2c;}
.hl-header{}
.hl-navbar{ position: fixed; left: 0px; width: 100%; z-index: 9; transition: all ease 0.75s;}
.cshnav{ display: flex; justify-content: space-between; align-items: center; width: 100%;}
.hl-navbar .navbar{box-shadow: none; background: transparent !important;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link{ font-weight: 600; text-transform: uppercase; padding:10px 20px; color: #000; transition: all ease 0.75s; position: relative; cursor: pointer;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link:hover, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link:focus, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link.active{ color: #005fff;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link::before, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link::after{content: ''; position: absolute;  background: #005fff; transition: all ease 0.75s; opacity: 0; border: none;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link::before{left: 50%; transform: translateX(-50%); width: 10px; height: 10px; border-radius: 50%;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link:hover::before, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link:focus::before, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link.active::before{animation: udop linear 0.75s;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link::after{width: 0px; height: 1px; left: 50%; transform: translateX(-50%); bottom: 0px;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link:hover::after, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link:focus::after, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link.active::after{ width: 100%; opacity: 1; transition-delay: 0.75s;}
.hl-navbar.fixednav {background: rgba(255, 255, 255, 0.1);backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);box-shadow: 0px 0px 10px rgba(0,0,0,0.21);}
.hl-banner{background: linear-gradient(to bottom, #deeefe 50%, transparent 100%); display: flex;}
.hl-banner h1{ font-size: 40px; text-transform: uppercase; font-weight: 900; color: #005fff;}
.hl-banner p{ font-weight: 600; font-size: 18px;} 
.hlb-img{ background: url('../images/home/h-bannercircle.png') no-repeat; background-position: top right; background-size: contain; width: 100%; padding:200px 0px 100px; display: flex; flex-direction: column; justify-content: center;}

.home-layout .input-group, .home-layout .btn{ border-radius: 0px;}
.home-layout .btn{text-transform: uppercase; font-size: 16px; height: 60px; min-width: 215px;}
.home-layout .input-group, .home-layout .form-control {border-color: #aec5e9;}
.home-layout .input-group:hover, .home-layout .form-control:hover {border-color: #005fff;}
.home-layout .btn-light{ background: #ebf2ff; color: #000000;}
.home-layout .btn-light:hover{ background: #fff; color: #005fff;}

.csnavbrand .navbar-brand{ display: inline-flex; height: 48px;}
.csnavbrand .navbar-brand img{ max-width: 100%; max-height: 100%;}

.cshnav {display: inline-flex;justify-content: center;flex-direction: row;align-items: center;width: 100%;}
.csv-search {display: flex; flex: 0.7 ; margin: 0px auto; z-index: 0; position: relative;}
.csv-search .btn{width: 100px;}
.navbar-collapse, .csnavbrand{ width: 315px; max-width: 315px;}
.home-layout .form-control{border-radius: 0;}
::input-placeholder { color:#005fff;}
.home-layout .form-control:-moz-placeholder {color:#005fff; opacity:1;}
.home-layout .form-control::-moz-placeholder{color:#005fff; opacity:1;}
.home-layout .form-control:-ms-input-placeholder {color:#005fff;}
.home-layout .form-control::-ms-input-placeholder {color:#005fff;}
.home-layout .form-control::placeholder {color:#005fff}
.hl-winnews{ background: linear-gradient(to right, #c2e1ff 0%, rgba(0,0,0,0) 85% ); height: 36px; display: flex; align-items: center; justify-content: center; font-weight: normal; position: relative; transition: all ease 0.75s;}
.hl-navbar.fixednav .hl-winnews {overflow: hidden; height: 0px;}
.hl-winnews img{ margin-right: 10px; width: 24px;}
.hl-winnews a{ text-decoration: underline; color: #000;}
.hl-winnews a:hover{color: #005fff; text-decoration: none;}
.hl-winnews span {font-weight: 500;}
.csv-search .btn {box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35) !important; min-width: auto; height: 100%;}
.hlb-content{ margin-bottom: 100px;}
.hl-banner p span:not(:last-child) {margin-right: 15px;}
.hl-banner p span:not(:first-child) {margin-left: 15px;}
.hl-banner p span:last-child{ color: #004bc8; text-decoration: underline; transition: all ease 0.75s;} 
.hl-banner p span:last-child:hover{ text-decoration: none;}

.hl-wboxes{ display: flex;}
.hl-wbox{ display: flex; background: #fff; box-shadow: 0px 2px 3px rgba(0,0,0,0.1); padding: 15px 30px; flex-direction: column; width: 315px; transition: all ease 0.75s; position: relative;}
.hl-wbox::before{content: ''; position: absolute; width: 100%; height: 100%; z-index: -1; transition: all ease 0.75s; left: 0px; top: 0px;}
.hlwboximg{ width: 100%; display: flex; align-items: center; justify-content: center; height:185px;}
.hlwboximg img{ max-width: 100%; max-height: 100%;}
.hlwboxcont h2{ font-size: 28px; text-transform: uppercase; text-align: center; font-weight: 600; color: #464646; min-height: 67px; display: flex; align-items: flex-end; justify-content: center;  transition: all ease 0.75s;}
.hlwboxcont h3{ font-size: 20px; color: #005fff;  text-align: center; font-weight: 600;  transition: all ease 0.75s;}
.hlwboxlink{ display: flex; justify-content: flex-end;}
.hlwboxlink a{ color: #2c2c2c;}
.hlwboxlink a svg{ fill: #2c2c2c; width: 16px;}
.hlwboxlink a:hover{ color: #005fff;}
.hlwboxlink a:hover svg{ fill: #005fff;}
.hl-wboxes .hl-wbox:not(:last-child){ margin-right: 30px;}
.hl-wbox:hover::before{box-shadow: rgba(5, 98, 255, 0.4) 5px 5px, rgba(5, 98, 255, 0.3) 10px 10px, rgba(5, 98, 255, 0.2) 15px 15px, rgba(5, 98, 255, 0.1) 20px 20px, rgba(5, 98, 255, 0.05) 25px 25px;}
.hl-wbox:hover h2{ color: #005fff;}
.hl-wbox:hover h3{ color: #464646;}
.hl-title{ margin-bottom: 30px;}
.hl-title h3, .hl-title h4{ text-transform: uppercase; font-weight: bold;}
.hl-title h3, .hl-title p{ font-size: 24px;}
.hl-title h4{ font-size: 40px;}
.hl-title p{ color: #005fff; font-weight: 600; margin-bottom: 0px;}
.hl-list li{ font-size: 16px; display: flex; border: 1px solid #fff; box-shadow: 0px 2px 3px rgba(0,0,0,0.1); background: #f8fbff; position: relative; align-items: center; color: #2c3d4d; transition: all ease 0.75s; padding: 10px 15px;}
.hl-list li:not(:last-child){margin-bottom: 10px;}
.hl-list li:hover{ background: #d1e3ff; color: #000;}
.hl-list li .hllfimg{ width: 32px; height: 32px; display: flex; align-items: center; justify-content: center; margin-right: 10px;}
.hl-list li .hllfimg img{ max-width: 100%; max-height: 100%;}

.hl-aya{ padding: 100px 0px;}
.hl-lform{ background: url(../images/home/h-schudle-demo-bg.png) no-repeat; padding: 30px; background-size: cover; height: 100%;  transition: all ease 0.75s;}
.hl-lform .hlfrm{ background: rgba(255,255,255,0); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); padding: 30px; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.60), 0 2px 5px rgba(0,0,0,0.1);}
.hl-lform .hlfrm h3{ font-weight: bold; font-size: 40px; text-align: center; margin-bottom: 50px;}
.hl-lform .hlfrm .form-group{ margin-bottom: 50px;}
.hl-lform .hlfrm .form-control{ border-right:none !important; border-left: none !important; border-top: none !important; background: transparent; padding-left: 0; padding-right: 0; font-size: 16px;}
.hl-lform .hlfrm .form-control:-moz-placeholder { opacity:1; font-size: 16px;}
.hl-lform .hlfrm .form-control::-moz-placeholder{opacity:1; font-size: 16px;}
.hl-lform .hlfrm .form-control:-ms-input-placeholder {font-size: 16px;}
.hl-lform .hlfrm .form-control::-ms-input-placeholder { font-size: 16px;}
.hl-lform .hlfrm .form-control::placeholder {font-size: 16px;}

@keyframes udop{
0% { top: -20px; opacity: 0; }
80% { top: 50%; opacity: 1; }
100% { top: 100%; opacity: 0; }
}
.hl-cb{ padding: 100px 0px;}
.hl-cb h3{ font-size: 24px; color: #005fff; font-weight: bold; text-transform: uppercase; margin-bottom: 15px;}
.hl-cb h4{ font-size: 30px; color: #2c2c2c; margin-bottom: 50px;}
.hlc-img{position: relative;}
.hlc-imgcric{position: absolute; right: 30px; top: 0px; z-index: -1;}

.fullbbg{position: relative;}
.fullbbg::before{position: absolute; width: 100%; content: ''; height: 60%; left: 0px; top: 50%; background: #ebf2ff; transform: translateY(-50%); z-index: -1;}
.hlc-imgrht{ display: flex; align-items: center; justify-content: flex-end;}
.hlc-imgrht .hlc-imgcric{ right: auto; left: 30px; top: 50%; transform: translateY(-50%);}

.hl-tech h4{font-size: 40px; font-weight: 700; text-transform: uppercase; text-align: left; margin-bottom: 30px;} 
.hl-tech p{font-size: 18px; line-height: 35px; text-align: right;}

.hl-price{ padding: 100px 0px;}
.hl-price h3{ text-align: center; margin-bottom: 50px; font-weight: 900; font-size: 40px; text-transform: uppercase;}
.hl-plisting{ width: 1000px; display: flex; padding: 15px; background: #fff; box-shadow: 0px 0px 5px rgba(16, 56, 114, 0.2); min-height: 300px; margin: 0px auto;}
.hlp-lft, .hlp-rht{ flex: 1; display: flex; flex-direction: column; height: 100%; align-items: flex-start; padding: 15px 0px 0px; }
.hlp-lft{ background: #ebf2ff;}
.hlp-lft p, .hlp-rht p{ font-size: 16px; margin-bottom: 0px; font-weight: 500; padding:0px 30px; }
.hlp-lft h4, .hlp-rht h4{ font-size: 32px; color: #005fff; font-weight: 500; margin-bottom: 30px; padding:0px 30px;}
.hlp-lft h5, .hlp-rht h5{color: #000;font-size: 35px; font-weight: 600; margin-bottom: 30px; padding:0px 30px;}
.hlp-lft ul{ margin-bottom: 50px; padding:0px 30px;}
.hlp-lft li{font-size: 14px; padding: 10px 15px 10px 30px; position: relative;}
.hlp-lft li::before{content: ''; background: url('../images/home/icons/list-icon.png') no-repeat; width: 18px; height: 18px; position: absolute; left: 0px; top: 50%; transform: translateY(-50%);}
.hlp-lft .btn, .hlp-rht .btn{ display: flex; margin: 0px auto 50px auto;}

.hlp-rht .hlp-blue{ background: #005fff; padding: 30px; display: flex; flex-direction: column; align-items: center;}
.hlp-rht .hlp-blue img{ margin-bottom: 25px;}
.hlp-rht .hlp-blue .btn{ margin-bottom: 21px; margin-top: 31px;}

.hl-footer{ padding: 50px 0px 30px; background: #fcfcfc;}
.hl-flist {display: flex;}
.hl-flogo{ width: 400px;}
.hl-flinks{ display:flex; justify-content: space-between; flex: 1; margin-bottom: 30px;}
.ftr__list{ flex-direction: column;}
.hl-flogo a {margin-bottom: 15px;}
.hl-flinks h4 {font-size: 20px; color: #000; margin-bottom: 20px; font-weight: 600;}
.hl-flinks ul li{ display: flex}
.hl-flinks li a{ color: #464646;}
.hl-flinks li a:hover{ color: #005fff;}
.hl-flinks ul li:not(:last-child) a { margin-bottom: 15px;}
.hl-copyrights{ display: flex; justify-content: space-between; padding-top: 30px; border-top: 1px solid #efefef;}
.hl-socicons a:not(:last-child){ margin-right: 15px;}
.hl-socicons a:hover svg{ fill: #005fff;}
.hl-cb img {max-width: 100%;}

.home-layout .navbar-toggler {display:none;flex-direction:column;align-items:center;justify-content:center; width:36px;height:36px; transition:all .75s ease; transition-delay: 0.8s; z-index: 0; position: relative; background: rgba(2, 96, 255, 0.22); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); border-radius: 0px;}
.home-layout .navbar-toggler span {background:#fff;width:30px;height:2px;border-radius:10px;display:flex;transition:all .75s ease;position:relative}
.home-layout .navbar-toggler span:not(:last-child) {margin-bottom:5px; }
.home-layout .navbar-toggler[aria-expanded=true] { position: fixed; right: 0; top: 0px;border-radius: 0; transition:all .75s ease; z-index: 1; background: rgba(255, 255, 255, 0.1);  -webkit-animation: 2s ease 0s normal forwards 1 fadein;    animation: 2s ease 0s normal forwards 1 fadein;}
.home-layout .navbar-toggler[aria-expanded=true] span {background:#fff; opacity: 0.5;}
@keyframes fadein{0% { opacity:0; }66% { opacity:0; }100% { opacity:1; }}
@-webkit-keyframes fadein{0% { opacity:0; }66% { opacity:0; }100% { opacity:1; }}
.home-layout .navbar-toggler[aria-expanded=true]:hover span {opacity:1;}
.home-layout .navbar-toggler[aria-expanded=true] span:nth-child(2) {width:0}
.home-layout .navbar-toggler[aria-expanded=true] span:first-child {transform:rotate(45deg);top:7px}
.home-layout .navbar-toggler[aria-expanded=true] span:nth-child(3) {transform:rotate(-45deg);top:-7px}
.home-layout .fixednav .navbar-toggler span{ background: #484848;}
.home-layout .fixednav .navbar-toggler {background: transparent;}

.hl-inner{ min-height: calc( 100vh - 365px); padding:0  0 100px;}
.hl-inner h1{ margin-bottom: 15px; font-weight: 800; text-transform: uppercase;}
.hl-inner p{line-height: 30px;}
.hl-innerheader{background: linear-gradient(to bottom, #deeefe 50%, transparent 100%); padding: 150px 0 50px;}
.list-numered li {padding-left: 50px;position: relative;z-index: 1; padding-bottom: 15px; margin-bottom: 30px;}
.list-numered li .numered {transition: all ease 0.75s;  position: absolute;left: 0px;font-size: 100px;color: #f0f0f0;font-weight: bold;font-family: 'Oswald', sans-serif;line-height: normal; z-index: -1; line-height: 95px;}
.list-numered li .shadowtippy{transition: all ease 0.75s; width: 100px; height: 100%; background: rgba(255, 255, 255, 0.8); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); top: 0px; left: 35px; position: absolute; box-shadow: rgba(0, 0, 0, 0.1) -25px 0px 25px -25px; z-index: -1;}  
.list-numered li h4 {color: #005fff;font-weight: 600; font-size: 20px;}
.list-numered li:hover .numered{color: #005fff;}
.list-numered li:hover .shadowtippy{box-shadow:rgba(2, 95, 255, 0.27) -25px 0px 25px -25px; }

.home-layout .dropdown-menu .dropdown-item{text-transform: uppercase; font-size: 14px; font-weight: 600;}

.hl-lform .hlfrm .form-group .flag-dropdown {background: transparent; border: none; border-radius: 0px;}
.hls-country .react-tel-input .form-control { padding-left: 50px;}
.hls-country .react-tel-input .selected-flag {border-radius: 0; background: transparent !important; border: none !important;}

.hl-boxshadow .hl-lform{ transition: all ease 0.75s; box-shadow:0px 0px 20px rgb(3, 96, 255); animation: shadows 1.5s infinite;}

@keyframes shadows {
    0% {
        transition: all ease 0.75s;
      box-shadow: 0px 0px 20px 5px rgb(3, 96, 255,);
    }
    50% {
        transition: all ease 0.75s;
      box-shadow: 0px 0px 20px 10px rgb(3, 96, 255);
    }    
    100% {
        transition: all ease 0.75s;
      box-shadow: 0px 0px 20px 5px rgb(3, 96, 255);
    }
  }


.home-layout .hl-footer .btn {font-size: 14px;height: 48px; min-width: auto; text-transform: none;}
.hlcimgcirc{ height: 300px;}
.imgcirc{position: absolute; width: 300px; height: 300px; left: 50%; transform: translateX(-50%);}
.hlc-imgcirc{ width: 100%; height: 100%;   }
.hlc-imgcirc-inner{width: 50px; height:50px;top: 0px; left: -35px; position: absolute;  }
.hlclogocir{ position: absolute; left: 0px; top: 0px; width: 100px; height: 100px; display: flex; align-items: center; justify-content: center; background-color: #fff; border-radius: 50%; padding: 15px;}
.hlc-imgcirc-inner:nth-child(2){ top: 215px;}
.hlc-imgcirc-inner:nth-child(3){ bottom: 85px;top: auto; right: -5px; left: auto;}
.hlccenterimg{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}

@media(max-width : 1366px) {
.hlb-img{ background-size: 50%;}
.hl-cb h4{ font-size: 26px;}
.hl-tech p{ font-size: 16px; line-height: 30px;}
.hl-cb h3{ font-size: 20px;}
}
@media(max-width : 1199px) {
.hl-lform .hlfrm h3{ font-size: 35px;}
.hl-lform{ height: auto;}
.hl-flinks h4 {font-size: 16px;}
.hl-flogo {width: 350px;}
.hl-plisting{ width: 100%;}
.hlp-lft ul, .hlp-lft h5, .hlp-rht h5, .hlp-lft p, .hlp-rht p, .hlp-lft h4, .hlp-rht h4{ padding:0px 15px;}
.hl-wbox{width: 292px;}
.hlwboxcont h2{ font-size: 25px;}
.hl-aya .container, .hl-plisting, .hl-cb .container {overflow: hidden;}
.hlc-imgcric{width: 270px; right: 0px;}
.hlc-imgrht .hlc-imgcric{left: -60px;}
.navbar-collapse, .csnavbrand{ width: auto;}
.csnavbrand{display: flex;}
.csnavbrand .navbar-brand{ padding: 0px; height: 40px;}
.hl-cb.fullbbg .container{ padding-top: 30px; padding-bottom: 30px;}
}
@media(max-width : 991px) {
.mobhide{ display: none !important;}
.home-layout .navbar-toggler {display:flex;}
.hl-flist{ flex-direction: column;}
.hl-flogo {width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center; margin-bottom: 30px;}
.hl-banner h1{font-size: 30px;}
.hl-banner p{ font-size: 14px;}
.hlwboxcont h2 {font-size: 18px;}
.hlwboxcont h3 {font-size: 12px;}
.hlwboxcont h2{ min-height: 43px;}
.hl-title h4 {font-size: 28px;}
.hl-title h3, .hl-title p{ font-size: 20px;}
.hl-listform .col-md-6, .hl-cb .col-md-6 {width: 100%;}
.hlc-img{ margin-bottom: 30px;}
.hl-cb .col-md-6 { text-align: center;}
.hl-cb.fullbbg .row{flex-direction: column-reverse;}
.fullbbg::before{ height: 100%;}
.hlc-imgrht{ justify-content: center;}
.hlc-imgrht .hlc-imgcric{ left: 50%;transform: translate(-50%, -50%); width: 245px;}
.hl-plisting{ flex-direction: column;}
.hlp-lft ul, .hlp-lft h5, .hlp-rht h5, .hlp-lft p, .hlp-rht p, .hlp-lft h4, .hlp-rht h4 {padding: 0px 30px;}
.hlp-rht .hlp-blue{ width: 100%;}
.hlp-rht .hlp-blue .btn{ margin-bottom: 50px;}
.hl-tech .col-md-6 { align-items: center !important;}
.hl-tech .col-md-6 p{ text-align: center !important;}
.hl-lform{ margin-top: 30px;}
.hlc-imgrht .hlc-imgcric + img {max-width: 60%;}
.hlp-rht { padding-bottom: 0px;}
.hlc-imgcric{ right: 5%;}
.hl-lform .hlfrm .form-group {margin-bottom: 15px;}
.home-layout .btn {font-size: 14px;height: 48px;min-width: 200px;}
.cshnav{ justify-content: space-between;}
.navbar-collapse {position: fixed;right: 0px;width: 0px; padding: 60px 30px; background:rgba(3, 96, 255, 0.7);top: 0px;height: 100vh !important; overflow: hidden; overflow-y: auto; backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.51); transform: translateX(100%); transition: all .75s linear;}
.navbar-collapse.show{width: 320px;transition: all .5s linear;right: 0;transform: translateX(0);}
.hl-navbar .navbar{ height: 52px;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link{ padding: 10px 0px;}
.hl-navbar .navbar-expand-lg .navbar-nav .nav-link, .hl-navbar .navbar-expand-lg .navbar-nav .nav-link:hover{color: #fff !important; }
.csv-search{ display: none;}
.hl-price h3{font-size: 30px;}
.home-layout .navbar-nav .dropdown-menu {position: static;display: block; background: #689fff; box-shadow: none;}
.home-layout .navbar-nav .dropdown-menu .dropdown-item{ color: #fff;}
.home-layout .navbar-nav .dropdown-menu .dropdown-item.active, .home-layout .navbar-nav .dropdown-menu .dropdown-item:focus, .home-layout .navbar-nav .dropdown-menu .dropdown-item:hover{ color: #005fff;}
}

@media(max-width : 767px) {
    .hl-wboxes{ flex-direction: column;}
.hl-wbox {width: 100%; margin-right: 0px !important; margin-bottom: 15px; flex-direction: row; flex-wrap: wrap; padding: 15px;}
.hlwboximg{width: 65px; height: 100%; margin-right: 15px;}
.hlwboxcont { align-items: flex-start; justify-content: center; display: flex; flex-direction: column; flex: 1;}
.hlwboxcont h2 {min-height: auto; text-align: left;}
.hlwboxlink {justify-content: center;align-items: center;width: 70px;}
.hl-banner h1 {font-size: 35px; text-align: center;}
.hl-banner p{ text-align: center; font-size: 16px;}
.hl-banner p span:not(:first-child) {margin-left: 10px;}
.hl-banner p span:not(:last-child) {margin-right: 10px;}
.hlb-content {margin-bottom: 50px;}
.hl-lform{ padding: 15px;}
.hlb-img{padding: 165px 0px 50px;}
.hl-aya, .hl-cb, .hl-price{ padding:55px 0px;}
.hl-title h4 {font-size: 22px;}
.hl-title h3, .hl-title p {font-size: 16px;}
.hl-listform ul li{ font-size: 14px;}
.hlp-rht .hlp-blue img{ max-width: 100%;}
.hlp-lft h4, .hlp-rht h4{font-size: 24px;}
.hlp-lft h5, .hlp-rht h5{font-size: 26px;}
}

@media(max-width : 575px){
.hl-flinks .nav h4 { cursor: pointer; }
.hl-flinks ul { max-height: 0; overflow:hidden; transition: max-height 1s ease-out; }
.hl-flinks .nav h4:after { content: "+"; float: right; transform: rotate(180deg); transition: all ease 0.75s; }
.hl-flinks .nav.open h4:after { content: "-"; transform: rotate(-180deg); transition: all ease 0.75s; }
.hl-flinks .nav.open ul { height:auto; max-height: 500px; transition: max-height 1s ease-in !important; }
.hl-flinks{ flex-direction: column; width: 300px; margin: 0px auto;}
}

@media(max-width : 475px) {
.hl-copyrights{ flex-direction: column-reverse; align-items: center; justify-content: center;}
.hl-socicons{ margin-bottom: 15px;}
.hl-banner h1{ font-size: 30px;}
.hl-banner p{ font-size: 14px;}
.hlc-imgcric {width: 200px;}
.hl-cb h4 {font-size: 18px;}
.hl-cb h3 {font-size: 16px;}
.navbar-collapse.show{ min-width: 100%;}
}
@media(max-width : 430px) {
.hl-banner h1 {font-size: 20px;}
.hl-listform ul li , .hlwboxlink, .hl-banner p {font-size: 12px;}
.hl-banner p span:not(:first-child) { margin-left: 12px; }
.hl-banner p span:not(:last-child) {margin-right: 12px;}
.hl-lform .hlfrm h3 {font-size: 22px;}
.hl-lform .hlfrm .form-control:-moz-placeholder {opacity:1; font-size: 12px;}
.hl-lform .hlfrm .form-control::-moz-placeholder{ opacity:1; font-size: 12px;}
.hl-lform .hlfrm .form-control:-ms-input-placeholder {font-size: 12px;}
.hl-lform .hlfrm .form-control::-ms-input-placeholder {font-size: 12px;}
.hl-lform .hlfrm .form-control::placeholder {font-size: 12px;}
.hlc-imgrht .hlc-imgcric{width: 195px;}
.hl-inner h1{ font-size: 20px;}
.imgcirc{ width: 245px; height: 245px;}
.hlclogocir{width: 65px; height: 65px; padding: 10px; overflow: hidden;}
.hlc-imgcirc-inner{ left: 0px;}
.hlc-imgcirc-inner:nth-child(2) {top: 185px;}
.hlc-imgcirc-inner:nth-child(3){right: -15px;}
}