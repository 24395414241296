@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '~mdb-ui-kit/css/mdb.min.css';
html, body, div#root, .dashboard-light-bg {height: 100%;}
body { margin : 0px;padding: 0px; font-family: 'Poppins', sans-serif; font-weight: 400; color: #141414; font-size: 14px;}
.margintop58 {margin-top: 58px}
.minwidth225 {min-width: 225px;}
a{outline: none; color: #005FFF; transition: all ease 0.75s;}
a:hover{ color: #000;}
img.sidebarlogo {max-width: 180px;margin: 0 auto;}
.dashboard-light-bg {background: url("../images/bg-light.jpg") no-repeat; background-size: cover; background-attachment: fixed;}
.ptb15{padding-top: 15px; padding-bottom: 15px;}
.h100vh{ height: 100vh;}
.profile-area{ display: flex; align-items: center; justify-content: flex-end; position: relative; z-index: 1;}
.profile-area p {font-size: 12px;}
.profile-area p span{color: #005fff; font-weight: 700 ;}
.lastlogin{ font-size: 12px; font-weight: 600;}
.sidemenupanel .list-group-item .sidebaricons{ height: 40px; width: 42px; display: flex; align-items: center; justify-content: center; padding: 0px 10px 0px 15px;}
.sidemenupanel .list-group-item .sidebaricons img{ max-width: 100%; max-height: 100%;}
.sidemenupanel .list-group-item.active .sidebaricons img{filter: brightness(0) invert(1);}
.backgroundblur { height: 100%;  border-radius: 27px; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgba(255,255,255, 0.4), rgba(255,255,255, 0.1)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); margin-left: 1px;
margin-right: 1px;}
nav#sidebarMenu {border-radius: 27px;}
.sitetextblue {color: #005fff;}
.bluetxttitle{ font-size: 20px; font-weight: 700;}
/* tbody tr:nth-child(even) {background: rgb(255 255 255 / 35%);} */
.row .table tr td, .row .table tr th {padding: 0.6rem 1rem;}
tbody tr td {border: 0px;}
.barchart {height: 240px;}
.scrolldiv {padding-right: 10px; height: calc(100vh - 60px);overflow-y: auto;overflow-x: hidden; padding-left: 5px;} 
::-webkit-scrollbar {width: 5px;}
/* ::-webkit-scrollbar-track {background: #f1f1f1; } */
::-webkit-scrollbar-thumb {background: #005fff; border-radius:10px;}
::-webkit-scrollbar-thumb:hover {background: #555; } 

.text-primary svg, .text-secondary svg{ width: 18px;}
.text-primary{ color: #005FFF !important;}
.text-primary svg{ fill: #005FFF;}
.btn-primary.text-primary svg{ fill: #fff;}
.superadminuserprofile { max-width: 50px;text-align: center; }

.text-secondary{ color: #636b79;}
.text-secondary svg{ fill: #636b79;}
.lighttxtclr{color: #9898b1;}
.form-group label{ color: #000032; margin-bottom: 5px;}
.table .form-group label{ margin-bottom: 0px;}
.badge{ font-weight: 500 !important; letter-spacing: 0.5px;}
.badge-primary { background-color: #e3edff; color: #005FFF; transition: all ease 0.75s;}
.btn{box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25) !important;  text-transform: none; padding: 0px 15px; height: 45px; border-radius: 10px; line-height: normal; display: inline-flex; align-items:  center; transition: all ease 0.75s; border: none; justify-content: center; font-size: 13px; font-weight: 600;}
.btn:hover .eva { animation-name: eva-flipInY;}
.btn-primary{  background: linear-gradient(to top, #005FFF 75%, #005FFF 95%);}
.btn-icon .eva{ margin-right: 5px; fill: #fff;}
.btn-primary:first-child:hover, .btn-primary:focus-visible, .btn-primary:hover, :not(.btn-check) + .btn-primary:hover{background: linear-gradient(to bottom, #fff 0%, #e8e8e8 100%); color: #005FFF;}
.btn-primary:hover .eva{fill: #005FFF;}

.btn-outline-primary{background: linear-gradient(to top, #ffffff 75%, #f1f1f1 95%); }
.btn-outline-primary:hover{background: linear-gradient(to top, #005FFF 75%, #005FFF 95%); color: #fff;}
.btn-outline-primary:hover .eva{fill: #fff;}
.btn-action{ width: 30px; height: 30px; padding: 0px; position: relative;}

[data-tooltip]:before,[data-tooltip]:after {position: absolute;left: 50%;transform: translate(-50%);-webkit-font-smoothing: antialiased;margin: 0 auto;opacity: 0;visibility: hidden;z-index: 9; transition: all ease-in-out 0s;}
[data-tooltip]:before {content: "";height: 0;width: 0;top: calc(100% - 5px);border-style: solid;border-width: 5px;border-color: transparent transparent #fff transparent;}
[data-tooltip]:after {content: attr(data-tooltip); white-space: nowrap; background: #fff; padding: 5px 10px; font-size: 10px; font-weight: 600; box-sizing: border-box;top: calc(100% + 5px);color: #000; text-transform: uppercase; border-radius: 10px;z-index: 9; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); letter-spacing: 0.5px}
[data-tooltip]:hover:before, [data-tooltip]:hover::after {opacity: 1; visibility: visible;transition: all ease-in-out 0.1s;}

.light-blur{position: relative; border-radius:27px; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);}
.dropdown-menu{border-radius:15px; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.9)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); overflow: hidden;}
.dropdown-menu .dropdown-item{ transition: all ease 0.75s; background: transparent; font-size: 12px; font-weight: bold; padding: 7px 10px;}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover{background: #D9E7FF; color: #005FFF;}
.dropdown-item.active .eva, .dropdown-item:active .eva, .dropdown-item:focus .eva, .dropdown-item:hover .eva{ fill: #005FFF;}

.container-fluid .row .table thead tr th {border-bottom: 1px solid white; }
.list-group-item-action:hover {  box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); border-radius: 10px; }
p.networkstatus::before {content: '';display: inline-block;width: 11px;height: 11px; background-color: #60E2AF;border-radius: 50%;margin-right: 6px;box-shadow: 0 0 0 3px #56c09a;}
.onlinestatus {width: 10px;height: 10px;background-color: #60E2AF;border-radius:50%;box-shadow: 0 0 0 3px #56c09a;}
nav#sidebarMenu {box-shadow: inset 0 0 15px 0 rgba(255,255,255,0.35), 0 0px 2px rgba(0,0,0,0.1); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15));}
span.badge-approved {background: #005fff;color: #ffffff;padding: 7px 11px;border-radius: 15px;min-width: 77px;box-shadow: 0em 0em 0.2em 0em #000000; transition: all ease 0.75s;}
span.badge-decline {background: #ee4b2b;color: #ffffff;padding: 7px 11px;border-radius: 15px;min-width: 77px;box-shadow: 0em 0em 0.2em 0em #000000; transition: all ease 0.75s;}
.list-group-item-action:active {background: none;}
.dashboardboxContainer {min-height: 230px; width: 100%; padding: 15px; display: flex; flex-direction: column; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0px 2px 5px rgba(0,0,0,0.15); z-index: 1; border-left: 1px solid #f2f2f2;  transition: all ease 0.75s; overflow: hidden;}
.dashboardsideContainer {min-height: 770px;}
.tableblur{border-radius: 20px;box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: rgb(255 255 255 / 50%); overflow: hidden; padding: 15px;}
.height100per {height: 100%;}
.sidebarbottom {position: absolute; bottom: 0px;right: 0;left: 0; margin: 15px; border-radius: 27px;    box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15));    -webkit-backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);    backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); padding: 15px;}
.list-group-item {background: none;border: 0px;}
.networkstatus{font-size: 13px;}
.sidebarbottom img {max-width: 70px;margin-bottom: 5px;}
.sidebarbottom .form-control {height: 40px !important; width: 150px; margin: 0px auto;  margin-bottom: 8px !important;}

.otp-input-fields {background-color: white;box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25) !important; max-width: 400px;width: auto;display: flex;justify-content: center;gap: 10px;padding: 4px 15px;border-radius: 10px;}
.otp-input-fields input {height: 40px;width: 40px;background-color: transparent;border-radius: 4px;border: 1px solid #005fff;text-align: center;outline: none;font-size: 16px;}
.otp-input-fields input::-webkit-outer-spin-button, .otp-input-fields input::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
.otp-input-fields input[type=number] {-moz-appearance: textfield;}
.otp-input-fields input:focus {border-width: 2px;border-color: #005fff;font-size: 20px;}


/* Sidebar */
.sidebar {position: fixed;top: 0;bottom: 0;left: 0;padding: 58px 0 0; /* Height of navbar */box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);width: 250px;z-index: 600; display: block !important; z-index: 9;}
.sidemenupanel .list-group-item.active {box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25); background: linear-gradient(to top, #005FFF 75%, #005FFF 95%);}
.sidemenupanel .list-group-item.active {color: #ffffff;}
.sidebar .active {border-radius: 10px;box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);}
.sidemenupanel .list-group-item {font-size: 12px;font-weight: 600; display: flex; align-items: center; margin: 0px !important; padding: 0px !important; height: 40px;}
.sidemenupanel i.fa {font-size: 20px;}
.sidebar-sticky {position: relative;top: 0;height: calc(100vh - 48px);padding-top: 0.5rem;overflow-x: hidden;overflow-y: auto; }
.sidemenupanel .list-group-item span { flex: 1; height: 100%; display: flex; align-items: center;}

.content-header{ margin-bottom: 30px; padding-left: 5px; padding-right: 10px;}

.teammemberprofile {border-radius: 50%;width: 55px;height: 55px;box-shadow: inset 0 0 30px 0 rgb(255 255 255 / 35%), 0 2px 5px rgb(0 0 0 / 15%);background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15));backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);overflow: hidden;padding: 1px;align-items: center;justify-content: center;}
.teammemberrow {border-top: 1px solid #ffffff;padding: 5px 0px; transition: all ease 0.75s; padding: 10px; display: flex; flex-direction: row; align-items: center;}
.temproname{ display: flex; flex: 1; align-items: center;}
.temproname img{ margin-right: 15px;}
.teammemberrow:hover{ background:#CCDFFF;}
.teammembername {margin-top: 16px;font-weight: 500;}
.teammembercert {color: #005fff; font-weight: 500; height: 100%; display: flex; align-items: center;}
.teammembercert p{ margin: 0px; font-size: 12px;}
.borderleft2pxwhite {border-left: 1px solid #ffffff;}
.chartdropdown {border-radius: 50px;background: #ffffff;margin-top: 25px;font-weight: 600;}
.profile-area .profile-icon{width: 45px; height: 45px; position: relative; margin-left: 15px; cursor: pointer; display: inline-flex; border-radius: 50%; overflow: hidden; transition: all ease 0.75s; box-shadow: inset 0 0 30px 0 rgb(255 255 255 / 35%), 0 2px 5px rgb(0 0 0 / 15%);background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15));-webkit-backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); border: 2px solid #fff;}
.profile-area .profile-icon .headerprofilepic {position: absolute;min-width: 100%; min-height: 100%; max-width: 55px; height: 55px; left: 50%;top: 50%;transform: translate(-50%, -50%);
}.profile-area .profile-icon:hover{box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25);   background: linear-gradient(to top, rgba(0,95, 255, 0.6), rgba(0,95, 255, 0.3)); border-color: #005fff; }
.profile-area p.profilename {font-size: 13px; display: flex; position: relative;}
.folder {padding: 27px 0 0px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;position: relative; margin-top: 5px;}
.folder::before {content: ""; width: 210px;height: 27px;border-radius: 27px 0px 0 0; box-shadow:0px 0px 10px rgba(0,0,0,0.05);   background: linear-gradient(to right, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); position: absolute;top: 1px;left: 0; z-index: 2; display: none; }
.folder::after { top: 0px;left: 182px;content: '';display: none;position: absolute;width: 40px;height: 27px;z-index: 1;transform: skewX(41deg); box-shadow:2px 0px 1px rgba(0,0,0,0.05);   background: linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);z-index: 1; }

.foldercut{border-left: 1px solid #ececec;  width:100%;  border-radius: 27px 0px 0 0;  overflow: hidden; display: inline-flex; align-items: center; align-self: flex-start;  }
.foldercut .cltitle { transition: all ease 0.75s; border-top: 1px solid #f2f2f2; padding: 0px 15px 0px 35px;  margin: 0 0 0 -20px; width: 75%; height: 35px; box-shadow:0px 0px 10px rgba(0,0,0,0.05); -webkit-transform: skew(40deg); -moz-transform: skew(40deg); -o-transform: skew(40deg); background: rgba(255, 255, 255, 0.65); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); }
.climgcont{-webkit-transform: skew(-40deg); -moz-transform: skew(-40deg); -o-transform: skew(-40deg); }

.folder .light-blur{ border-radius: 0px 27px 27px;}

.cltitle{ display: flex; align-items: center; font-size: 12px; text-transform: uppercase; z-index: -1; color: #1c1c1c; transition: all ease 0.75s; }
.cltitle img{ width: 18px; margin-right: 3px;  left: 0px;}

.folder:hover .cltitle{ background: rgb(227, 237, 255);}
.folder:hover .dashboardboxContainer{background: #cfe0ff;}

.cpartitle{ font-weight: 600; text-transform: uppercase; font-size: 20px;}
.foldcont{ display: flex; flex: 1;  flex-direction: column; justify-content: center;}
.foldcont h2{ margin: 0px;}
.foldpicshare{ min-height: 44px;display: flex; justify-content: space-between; flex-direction: row; align-items: center;}
.row-reverse{flex-direction: row-reverse;}
.foldpicshare{ position: relative; bottom: 0; transition: all ease 0.75s;}
/* .folder:hover .foldpicshare{ bottom: 0; opacity: 1;} */
.foldpics ul{ display: flex; flex-direction: row; margin: 0px;}
.foldpics li:not(:first-child){ margin-left: -25px;}
.flpic{ transition: all ease 0.75s; cursor: pointer; border-radius: 50%; width: 44px; height: 44px; backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); background: transparent; display: flex; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); overflow: hidden; padding: 1px; align-items: center; justify-content: center; transition: all ease 0.75s;}
.flpic img{ position: relative; min-width: 100%; min-height: 100%; max-width:55px; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 50%;}
.flpic.last-count{ overflow: visible; font-size: 12px; font-weight: 600; color: #005FFF;}
.flpic:hover, .icontext:hover .icon{ z-index: 1; position: relative; color: #fff; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25); background: linear-gradient(to top, rgba(0,95, 255, 0.6), rgba(0,95, 255, 0.3));}

.foldshare{font-size:14px; color: #005FFF; display: flex; align-items: center; cursor: pointer; transition: all ease 0.75s;}
.foldshare svg{ fill: #005FFF; width: 22px; height: 22px;  margin-right: 5px; transition: all ease 0.75s;}
.foldshare:hover{ color: #141414;}
.foldshare:hover svg{ fill: #141414;}
.createcetr h5{ font-size: 20px;}
.createcetr h4{ font-size: 25px; margin-bottom: 30px;}
.createcetr p{ font-size: 13px;}

.icontext{ display: flex; align-items: center; cursor: pointer; flex-direction: row;}
.icontext .icon{transition: all ease 0.75s; width: 30px; height: 30px; border-radius: 50%; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: linear-gradient(to top, rgba(255,255,255, 0.6), rgba(255,255,255, 0.15)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); display: flex; align-items: center; justify-content: center; margin-right: 10px; }
.icontext .icon svg{transition: all ease 0.75s; fill: #005FFF;}
.icontext .text{transition: all ease 0.75s; text-transform: uppercase; font-size: 13px; font-weight: 700; color: #333333;}
.icontext:hover .icon svg{fill: #fff;}
.icontext:hover .text{ color: #005FFF; }

.viewall{ flex-direction: row-reverse;}
.viewall .icon, .icon-sm .icon{ margin: 0px 0px 0px 10px; width: 22px; height: 22px;}
.icon-sm .icon{ margin: 0px 10px 0px 0px;}
.viewall .text{ font-weight: 500; text-transform: none;} 
.viewall .icon svg, .icon-sm .icon svg{transition: all ease 0.75s; fill: #464646; width: 15px; height: 15px;}


.custable th{ font-size: 12px;   border-bottom: 1px solid #E0E3E4 !important; color: #666666; font-weight: 600; white-space: nowrap;}
.custable td{ color: #222; font-weight: 500; font-size: 13px;}
.custable .text-success, .custable .text-danger, .custable .text-primary{ font-size: 11px; position: relative;}
.custable .text-success{ color: #04AF15 !important;}
.custable .text-danger{ color: #F96262 !important;}
.custable .text-dark{ color: #000 !important;}
.custable .dropdown-toggle::after{ display: none;}

.btngrouprht a:not(:first-child), .btngrouprht button:not(:first-child){ margin-left: 15px;}
.cs-plan .btngrouprht a:not(:first-child), .cs-plan .btngrouprht button:not(:first-child){ margin-left: 0px;}
.cs-plan .btngrouprht a, .cs-plan .btngrouprht button{ margin-right: 15px; margin-bottom: 15px;}

.searchform{ display: flex; padding: 15px 0px; align-items: center; border-bottom: 1px solid #E0E3E4;}
.searchform .fields{ flex: 1;}
.searchform .fields:not(:last-child){ margin-right: 15px; }
.searchform .fields:not(:first-child){ margin-left: 15px; }
.form-control{ border-color: #e5e3ef; border-radius: 10px; height: 48px !important; transition: all ease 0.75s; font-size: 13px; color: #000;}
.form-control:focus, .form-control:hover {border: 1px #005FFF solid; box-shadow: none;}
.form-control:hover .eva { animation-name: eva-flipInY;}

.form-control::-webkit-input-placeholder { color: rgba(51, 51, 51,0.5); font-size: 13px;}
.form-control:-ms-input-placeholder {color: rgba(51, 51, 51,0.5);  font-size: 13px;}
.form-control::placeholder {color: rgba(51, 51, 51,0.5); font-size: 13px;}

select.form-control{ appearance: none; -moz-appearance: none; -webkit-appearance: none; background: #fff url(../images/icons/chevron-down.png) no-repeat; background-position: 100% center; padding-right: 48px;}

input[type="checkbox"], input[type="radio"]  {display: none;}
input[type="checkbox"] + *, input[type="radio"] + *{width: 1rem; height: 1rem; padding: 0px;}
input[type="checkbox"] + *::before, input[type="radio"] + *::before {content: ""; display: flex; justify-content: center;  align-items: center;  vertical-align: middle;  width: 1rem;  height: 1rem;  border-style: solid;   border-width: 0.1rem;  border-color: #DADADA;  flex-shrink: 0;  transition: all ease 0.75s; border-radius: 4px; background: #fff;}
input[type="checkbox"]:checked + *::before, input[type="radio"]:checked + *::before {  content: "\2713";  color: #fff;  text-align: center;  background: linear-gradient(to top, #005FFF 75%, #005FFF 95%); border-color: transparent;  font-weight: bold;}
input[type="checkbox"] + *, input[type="radio"] + * {  display: flex; position: relative;}
input[type="radio"] + *::before, input[type="radio"]:checked + *::before{border-radius: 50%;}

.logincon{display: flex;}
.loginimg{flex: 1; display: flex; position: relative;}
.loginform{width: 620px; min-height: calc(100vh - 80px); align-items: center; display: flex;justify-content: center; box-shadow: -10px 0px 10px #2b428242; padding: 30px; background-color: #fff; flex-direction: column; height: 100%; overflow-x: hidden; overflow-y: auto; position: relative;}

.main-logo {align-items: center;display: flex; height: 34px;width: 100%; margin-bottom: 50px;}
.form-group{margin-bottom: 15px;}
.loginform .title{margin-bottom: 10px; text-align: left;font-weight: bold; font-size: 35px;}
.loginform .title + p{ font-size: 14px;}
.loginform .title span {position: relative;font-size: 45px;top: -5px;}
.loginform .form-group{ text-align: left; margin-bottom: 20px;}
.loginform .form-group label{ font-size: 14px; font-weight: 500; }
.loginbtn .btn {width: 100%;font-size: 15px;}

.loginimg img {position: absolute; height: 100%; pointer-events: none; width: 100%;top: 0px; left: 0px;}
input[type="file"] { display: none;}
.custom-file-upload {display: flex;align-items: center; color: rgba(51, 51, 51,0.5); font-size: 13px; justify-content: space-between; cursor: pointer;}
.border-none{border: none !important;}
.custom-file-upload .eva{ fill: rgba(51, 51, 51, 0.5);}
.loginform .form-group.cbwtxt{margin-bottom: 0px;}
.cbwtxt .form-check-label {display: flex; align-items: center; margin: 0px;}
.cbwtxt .form-check-label::before{ margin-right: 5px; } 

.greenbox{ display: flex; flex-direction: column; border-radius: 27px; overflow: hidden; box-shadow:0 0px 5px rgba(0,0,0,0.1); margin-bottom: 15px;}
.greencont{background: linear-gradient(to right, #005FFF 0%, #3A80FF 95%); min-height: 46px; color: #fff; padding: 15px; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25);} 
.mapimportdata{ display: flex; background: rgb(255 255 255 / 50%); padding: 15px;}
.mapimportdata .data{ flex: 1;}
.mapimportdata .data p{font-size: 12px; margin-bottom: 10px;}
.mapimportdata .data h5{font-size: 13px; margin-bottom: 0px;}

.table th{ color: #464646; text-transform: uppercase; font-weight: 600;}
.table > tbody > tr{ background: #fff;}
.table tr:not(:last-child){ transition: all ease 0.75s; border-bottom: 1px solid #e0e3e4;}

.table-hover > tbody > tr:hover{background: rgb(240, 245, 255); position: relative; z-index: 1; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);}

.imgpreviewbox{ display: flex; align-items: center; justify-content: center; flex-direction: column; border: 1px solid #e5e3ef; background: #fff; border-radius: 10px; width: 100%; height: 300px; color: rgba(51, 51, 51, 0.5);  font-size: 13px; transition: all ease 0.75s; position: relative;     overflow: hidden;}
.imagename {position: absolute;bottom:0; right: 0;background: #e4eeff;padding: 5px 5px 5px 10px;border-radius: 10px 0px 0px 0px;font-size: 11px;color: #111;box-shadow: 0px 0px 3px rgba(0,0,0,0.1);backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);}
.imgpreviewbox:hover{ border-color: #005FFF;} 
.imgpreviewbox .eva{ width: 175px; height: 150px; stroke: #fff; fill: rgba(0, 95, 255, 0.2);}
.imgpreviewbox:hover .eva { animation-name: eva-flipInY;}

.input-group{border: 1px solid #e5e3ef; border-radius: 10px; transition: all ease 0.75s;}
.input-group .input-group-text {height: 48px; border-radius: 10px 0px 0px 10px; background: #fff; border:none; font-weight: bold; width: 48px; display: flex;  align-items: center;  justify-content: center;}
.input-group > .form-control, .input-group > .form-control:focus{border: none !important; box-shadow: none;}
.input-group:hover, .input-group:focus{box-shadow: none; border-color:#005FFF; }
.pickclr{ width: 100%; height: 48px; border-radius: 10px; display: flex; border: 2px solid #fff; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);}

.btn-toolbar{ padding: 0px; overflow: hidden; border-radius: 10px; }
.btn-toolbar .btn-group{ box-shadow: none; display: flex; margin: 0px !important; flex: 1;}
.btn-toolbar .btn{box-shadow: none !important; display: flex; flex: 1; color: #464646; border-radius: 0px; height: 48px; min-width: auto !important;}
.btn-toolbar .btn:not(:first-child){ border-left: 1px solid #EFEFEF;}
.btn-toolbar .btn:hover{ background: #CCDFFF; color: #000;}
.btn-toolbar .bold{font-weight: bold;}
.btn-toolbar .italic{ font-style: italic;}
.btn-toolbar .underline{text-decoration: underline;}
.btn-toolbar .linethrough{text-decoration: line-through;}

.draganddrop{ min-height: 355px; cursor: pointer;}
.formsubhead{font-size: 13px; text-transform: uppercase; font-weight: bold;}

#main-navbar{  padding: 0; background: #045dfb !important; border-radius: 0px; box-shadow: 0px 2px 3px rgba(0,0,0,0.4); display: none;}
.mobnav{ display: flex; align-items: center; justify-content: space-between; flex: 1;}
.mobnav .logo, .mobnav .navtoggle{ width: 48px; height: 48px; display: flex;}
.mobnav .logo .navbar-brand{ margin: 0px; padding: 5px;}
.mobnav .logo img{max-width: 100%; filter: brightness(0) invert(1);}
.mobnav .cmpname{ flex: 1; display: flex; justify-content: center; align-items: center}
.mobnav .cmpname h5{ color: #fff; margin-bottom: 0px; white-space: nowrap;max-width: 85%;text-overflow: ellipsis;overflow: hidden;}
.mobnav .bars{ width: 28px; height: 2px; border-radius: 30px; display: block; background: #fff; transition: all ease 0.75s; position: relative; transform: rotate(0deg);}
.mobnav .bars:not(:first-child){ margin-top:5px;}
.mobnav  .navbar-toggler{ padding: 0px; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column; border-radius: 0px; transition: all ease 0.75s;}
.mobnav  .navbar-toggler[aria-expanded="true"] .bars:nth-child(2){ width: 0px;}
.mobnav  .navbar-toggler[aria-expanded="true"] .bars:first-child{ transform: rotate(45deg); top: 7px;}
.mobnav  .navbar-toggler[aria-expanded="true"] .bars:last-child{ transform: rotate(-45deg); bottom: 7px;}
.mobnav  .navbar-toggler[aria-expanded="true"]{ background: rgba(0, 0, 0, 0.1);}

.ctemp{ position: relative; text-align: center; margin-bottom: 30px;}
.dis-bfore::before{  content: '';position: absolute;background: rgba(255, 255, 255, 0);top: 0px;left: 0px;z-index: 1;width: 100%;height: 100%; border-radius: 27px;}
.ctemp .img{ max-width: 100%; padding: 15px; display: flex;}
.ctemp .img img{ width: 100%; max-height: 240px;}
.ctemp .cername{ padding: 0px 0px 15px; transition: all ease 0.75s;}
.ctemp input[type="checkbox"] + *, .ctemp input[type="radio"] + *{ width: 100%; height: auto; flex-direction: column;}
.ctemp  .backgroundblur{cursor: pointer; transition: all ease 0.75s; border:1px solid #fff}
.ctemp input[type="checkbox"] + ::before, .ctemp input[type="radio"] + ::before{ position: absolute;right: 10px;top: 10px;border: none;border-radius: 50%; width: 24px; height: 24px; transition: all ease 0.75s;  background-color: transparent;}
.ctemp:hover .backgroundblur, .ctemp input[type="checkbox"]:checked + *, .ctemp input[type="radio"]:checked + * {  background: linear-gradient(to top, rgba(0, 95, 255, 0.2), rgba(0, 95, 255, 0.05)); border-color: #005FFF;}
.ctemp:hover .backgroundblur, .ctemp input[type="checkbox"]:checked + * .cername{ color: #005FFF;}
.ctemp input[type="checkbox"]:checked + ::before, .ctemp input[type="radio"]:checked + ::before{ box-shadow: inset 0 0 10px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25) !important;}

.issuecerttabs .tab-pane {margin-top: 30px;}
.issuecerttabs .nav-pills .nav-item .nav-link {margin: 0px; border-radius: 10px; position: relative;  border: 1px solid #E0E0E0; background: #FFFFFF; padding: 0px 40px; transition: all ease 0.75s; height: 46px;
display: flex; align-items: center;justify-content: space-between;}
.issuecerttabs .nav-pills .nav-item .nav-link .eva{ position: absolute; right: 8px; top: 50%; transform: translateY(-50%); fill: #fff; opacity: 0; transition: all;}
.issuecerttabs .nav-pills .nav-item:not(:last-child) .nav-link{ border-radius: 10px 50px 50px 10px;} 
.issuecerttabs .nav-pills .nav-item:not(:first-child){ margin-left: -30px;}
.issuecerttabs .nav-pills .nav-item .nav-link[aria-selected="true"], .issuecerttabs .nav-pills .nav-item .nav-link:hover, .issuecerttabs .nav-pills .nav-item .nav-link.active{  border-color: #005FFF; color: #005FFF; box-shadow: inset 0 0 10px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25)}
.issuecerttabs .nav-pills .nav-item:first-child{z-index: 9;}
.issuecerttabs .nav-pills .nav-item:nth-child(2){z-index: 8;}
.issuecerttabs .nav-pills .nav-item:nth-child(3){z-index: 7;}
.issuecerttabs .nav-pills .nav-item:last-child{z-index: 6;}
.issuecerttabs .nav-pills .nav-item .nav-link.finished{border-color: #5992F1; box-shadow: inset 0 0 10px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25); background: linear-gradient(to top, #005FFF 75%, #005FFF 95%); color: #fff;}
.issuecerttabs .nav-pills .nav-item .nav-link.finished .eva{ opacity: 1;}
li .nftlistborder { border-bottom: 1px solid #a6c5f9;  padding-bottom: 13px;    min-width: 300px;}
li:last-child .nftlistborder {    border-bottom: 0px;    padding-bottom: 5px;}

.searchform .fields.txtfields{ flex: 0.5 0.5;}
.icon-rht .eva{ margin-left: 5px; margin-right: 0px;}
.custable .form-group{ margin: 0px;}
.formscroldiv {height: calc(100vh - 315px); overflow: hidden; overflow-y: auto; margin-bottom: 15px;}

.certinfo{ padding: 30px; min-height: 100%;}
.certinfocont{ display: flex; align-items: center; justify-content: center;}
.certinfocont p{ margin-bottom: 30px;}
.certinfocont p:not(:last-child){ margin-right: 50px;}
.certinfo .custom-file-upload{ display: inline-flex; color: #fff;}
.certinfo .custom-file-upload .eva{ fill: #fff;} 
.certinfo .custom-file-upload:hover .eva{ fill: #005FFF;} 
.certinfo .form-group{ margin: 15px;}
.certinfo h3{ font-size: 20px; margin-bottom: 30px;}
.certinfo .img img{  max-height: 350px;}

.btn-light .eva{ fill: #4f4f4f;}
.formscroldiv .backgroundblur {height: auto; }
.mh-auto{max-height: inherit !important;}

.certpreview .img img {height: 400px;}
.certprecontbtn{ margin:30px 0px;}

.cpbtnlinks{ display: flex; flex-direction: column; align-items: flex-start;}
.cpbtnlinks a{ margin-bottom: 15px; display: flex; align-items: center; justify-content: center;}
.cpbtnlinks a.btn{ height: auto; padding: 5px 15px;}
.listviewtxts {display: flex;flex: 1;font-size: 13px;}
.studname, .studid{ flex: 1;}
.studbetch{ width: 100px;}
.studdep{ flex: 1;}
.listview .accordion-item{ margin-bottom: 15px; border-radius: 27px; overflow: hidden;border: none; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25); transition: all ease 0.75s;}
.listview .accordion-button{padding: 15px; min-height: 46px;}
.listview .accordion-button[aria-expanded="true"], .listview .accordion-button:hover{background: linear-gradient(to right, #005FFF 0%, #3A80FF 95%); color: #fff; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35);}
.listview .accordion-button:not(.collapsed)::after, .listview .accordion-button:hover::after{filter: brightness(0) invert(1);}

.signerboxes{padding: 30px; display: flex; flex-direction: column; align-items: center;  margin-top: 30px;}
.signerboxes h6{ margin-bottom: 30px;}
.signerboxes .btn{text-transform: uppercase; margin-bottom: 15px; width: 150px;}
.signerboxes p{ margin-bottom: 10px;}
.btn-abs{ position: relative; padding-right: 42px;}
.btn-abs .eva{ position: absolute; right: 10px; top: 50%; transform: translateY(-50%);}
.btn-abs::after{content: ''; position: absolute; right: 42px; height: 100%; width: 1px; background: rgba(0, 0, 0, 0.1); top: 0px;}
.btn-disabled{pointer-events: none;}
.light-brd { border-color: #a6b9c8;}

.sidemenupanel {overflow: hidden;overflow-y: scroll;height: calc(100vh - 200px); padding: 20px 15px;}
.mobvis{ display: none !important;}

.alert-top{ position: absolute; top: 0px; width: 100%; border-radius: 0px; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); }

.profile-cont .btn-sm{ padding: 0px 8px; font-size: 11px; height: auto; border-radius: 8px; color: #333 !important; background: #C4DAFF;}
.profile-cont .btn-sm .eva{ margin-right: 5px; fill: #333; transition: all ease 0.75s;} 
.profile-cont .btn-sm:hover{ color: #333 !important; background: #fff;}
.profile-cont .btn-sm:hover .eva{fill: #333;}

.cert-loader .backgroundblur{ min-height: 300px; display: flex; padding: 30px; align-items: center; justify-content: center; height: auto !important; margin-bottom: 30px;}

.mintnft .img img{ max-height: inherit; max-width: 100%; width: auto; max-height: 65px;}
.mintnft .ctemp .backgroundblur{ align-items: center; justify-content: center; flex-direction: column; display: flex; min-height: 225px;}
.mintnft .ctemp .backgroundblur h4{ font-size: 20px; color: #464646; font-weight: 400;}
.mintnft .ctemp .backgroundblur h3{ font-size: 25px; font-weight: 700; text-transform: uppercase; color: #005FFF; }
.mintnft .ctemp .backgroundblur .img{padding: 0; margin-bottom: 15px;}
.p-15px{padding: 15px;}
.listview .accordion-body img{max-height: 475px;}
.createcetr {position: relative;}
.mobileprofile{ background: #DFEBFD; padding: 15px; margin-bottom: 0px; border-radius: 10px; display: none; margin-top: 15px;}
.mobileprofile .profile-icon {margin: 0px auto 5px auto; width: 65px;height: 65px;}
.mobileprofile .profile-icon .headerprofilepic{ height: 70px; max-width: 70px;}
.mobileprofile .profile-area {flex-direction: column;align-items: center;justify-content: center;text-align: center;}
.mobileprofile .profile-area p.profilename {display: flex;}
.mobileprofile .profile-area p.profilename .username { margin-left: 5px;}
.main-content ,.loginform .form-group label{width: 100%;}
.certsteps div, .certsteps a {font-size: 13px !important;font-weight: 600 !important;text-transform: uppercase;}
.certsteps > div > div > div div:first-child a, .certsteps > div > div > div div:first-child span{ line-height: normal !important;display: flex;align-items: center;justify-content: center;height: 100%;}
.certsteps > div > div > div div:first-child span{ color: #a4a4a4 !important;}
.recharts-surface tspan {font-size: 10px;}
.accordion-collapse{  transition: all ease 0.75s;}
.loginform .main-content {width: 400px;}
.btngrouprht {min-width: 100px;}
.studentdash .ctemp .backgroundblur{min-height: 260px;}
.sidebar .accordion-borderless .accordion-item .accordion-button:not(.collapsed) {background: transparent;color: #4f4f4f;}
.sidebar .accordion-button::after{transform: rotate(0deg) !important;filter: grayscale(1); transition: all ease 0.75s; margin-right: 10px;} 
.sidebar .accordion-button:not(.collapsed)::after{ }
.sidebar .accordion-button[aria-expanded="true"]::after{transform: rotate(180deg) !important;}
.sidebar .accordion-borderless .accordion-item .accordion-button:not(.collapsed) {background: transparent;color: #4f4f4f;}
.sidebar .accordion-button[aria-expanded="true"] {background: #cee0ff !important;}

.addemailaccount .backgroundblur{ padding: 15px; margin-bottom: 15px;}
.addemailaccount .backgroundblur h4{ margin-bottom: 15px;}

.studentcert .ctemp .backgroundblur{ padding: 15px; align-items: normal ; justify-content: normal; cursor: default;}
.studentcert .ctemp .backgroundblur iframe, .iframe{ width: 100%; min-height: 500px;}
.inputbtngroup .btn{ height: 48px; padding-top: 0px;}
.accbodyiframe iframe{ width: 75%; margin: 0px auto;}

.tableblur .fields .form-control {height: 40px !important;}

.studentdash .folder{cursor: pointer;}

.cert-full-loader{ position: fixed; width: 100%; height: 100%; z-index: 9999; top: 0px; left: 0px;}
.cert-full-loader .backgroundblur{ display: flex; align-items: center; justify-content: center; background: rgba(255, 255, 255, 0.1);}

.fadein{animation: fadeIn 0.75s ease-in both;}
.icontext.eva-hover {display: inline-flex;}


@keyframes fadeIn {
from {opacity: 0;transform: translate3d(0, -5%, 0);}
to {opacity: 1;transform: translate3d(0, 0, 0);}
}

@keyframes fadeDown {
  0% {
      opacity: 0;
      transform: translateY(-100px);
  } 
  100% {
      opacity: 1;
      transform: translateY(6px);
  }
}

.userOrg{ display: flex; flex-direction: row; align-items: center;}
.userOrg img {height: 20px; margin-right: 7px; }
.userOrg h5{ margin: 0px; font-size: 16px;}

.certsteps div, .certsteps a, .certsteps span {transition: all ease 0.75s;}

.checkcross{ margin-bottom: 0px;}
.checkcross svg {width: 65px;display: block; margin: 0px auto;}
.checkcross .path {stroke-dasharray: 1000;stroke-dashoffset: 0;}
.checkcross .path.circle {-webkit-animation: dash 0.9s ease-in-out;animation: dash 0.9s ease-in-out;}
.checkcross .path.line {stroke-dashoffset: 1000;-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;animation: dash 0.9s 0.35s ease-in-out forwards;}
.checkcross .path.check {stroke-dashoffset: -100;-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;animation: dash-check 0.9s 0.35s ease-in-out forwards;}

@-webkit-keyframes dash {
0% {stroke-dashoffset: 1000;}
100% {stroke-dashoffset: 0;}
}
@keyframes dash {
0% {stroke-dashoffset: 1000;}
100% {stroke-dashoffset: 0;}
}
@-webkit-keyframes dash-check {
0% {stroke-dashoffset: -100;}
100% {stroke-dashoffset: 900;}
}
@keyframes dash-check {
0% {stroke-dashoffset: -100;}
100% {stroke-dashoffset: 900;}
}

.loaderbtn { margin-left: 10px;width: 30px; height: 30px; display: flex;padding: 3px;background: #fff;border-radius: 50%;}

.lastestnfts ul li a{ display: flex; flex-direction: row; align-items: center; color: #111;  transition: all ease 0.75s;}
.lastestnfts ul li a:hover{ color: #005fff;}
.lastestnfts ul li:not(:last-child){ margin-bottom: 15px;}
.lastestnfts ul li a .img{ width: 40px; margin-right:5px; height: 100%;}
.lastestnfts ul li a .lnftscont{ display: flex; flex-direction: column;}
.lastestnfts ul li a .lnftscont p{ margin-bottom: 5px; color: #717274;}
.lastestnfts ul li a .lnftscont h6{ margin-bottom: 0px; font-size: 15px;}
.lastestnfts h5 {font-size: 18px;margin-bottom: 15px;}
.listcorgrade .badge-primary { background-color: #caddff; color: #313131;}
.listcorgrade .badge{padding: 5px 10px; font-size: 12px; cursor: pointer; transition: all ease 0.75s; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); margin-bottom: 10px;} 
.listcorgrade .badge:hover{ background: #fff; color: #005fff;}
.listcorgrade .badge:not(:last-child){ margin-right: 10px; }

.pnf{ display: flex; align-items: center; flex-direction: column; justify-content: center; height: calc(100% - 95px);}
.pnf img{ max-width: 30%; margin-bottom: 30px;}
.pnf h6{ font-size: 20px;}

.institle{ font-size: 14px; padding: 7px 10px;}

.btn-tran{ box-shadow: none !important; padding: 0px 5px; height: auto; font-size: 11px; text-transform: uppercase; color: #005fff;}
.btn-tran .eva{ width: 16px; fill: #005FFF;}

.vtlinks{ height: 100%; display: flex; justify-content: flex-end; align-items: center;}
.vtlinks a{ color: #005FFF; transition: all ease 0.75s; font-weight: bold; text-transform: uppercase; padding: 0px 15px; }
.vtlinks a:hover{ color: #000;}
.vtlinks a:not(:last-child){ border-right: 1px solid #a7a7a7;}

.btnwithpro{ display: flex; flex-direction: row; align-items: center; justify-content: flex-end;}
.btnwithpro .content-header{ margin-left: 30px;}
/* .table-scroller{ height:calc(100vh - 230px); overflow-y:auto ;} */


.bs-tooltip-bottom .tooltip-inner{background: #fff; border-radius: 10px; color: #000; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); opacity: 0; position: relative; bottom:-10px; transition: all ease 0.75s; font-size: 10px; font-weight: 600; text-transform: uppercase; letter-spacing: 0.5px;}
.bs-tooltip-bottom.show .tooltip-inner{opacity: 1; bottom: 0px;}

.accessToken {word-break: break-all;    white-space: normal;  transition: all ease 0.75s;  }

.backtit{ display: flex; flex-direction: row; align-items: center; margin-bottom: 15px;}
.backtit .btn {margin-right: 15px;transition: all ease 0.75s;width: 40px;overflow: hidden;justify-content: flex-start;padding-left: 10px;}
.backtit .btn .eva{ margin-right: 30px; transition: all ease 0.75s;} 
.backtit .btn:hover{ width: 100px; padding-left: 15px; }
.backtit .btn:hover .eva{ margin-right: 10px;}

.acboxs{padding: 15px;max-height: calc(100vh - 240px);background: #fff;min-height: 100%;border-radius: 16px;overflow-y: auto;}
.btn-lightbtn{border: 1px solid transparent; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); }
.btn-lightbtn:hover, .btn-lightbtn.active {border-color: #BCD3FB; background: linear-gradient(to top, rgba(0, 95, 255, 0.05), rgba(0, 95, 255, 0.01));}
textarea.form-control{ height: auto; min-height: 150px;}
.largebtn .badge{ height: 46px; padding: 0px 15px; display:inline-flex; align-items: center; justify-content: center; border: 1px solid #E1E1E1; border-radius: 10px; background: #F3F3F3; font-weight: 600; color: #464646;}
.largebtn .badge:hover{border-color: #BCD3FB;  background: #D9E7FF; color: #005fff;}

.toggle {cursor: pointer;display: inline-flex;}
.toggle-switch {display: inline-block;background: #ccc;border-radius: 16px !important;width: 58px !important;height: 32px !important;position: relative;vertical-align: middle;transition: background 0.25s;}
.toggle-switch:before, .toggle-switch:after {content: "";}
.toggle-switch:before {display: block !important;background: linear-gradient(to bottom, #fff 0%, #eee 100%) !important;border-radius: 50% !important;box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) !important;width: 24px !important;height: 24px !important;position: absolute;top: 4px;left: 4px;transition: left 0.25s;}
.toggle:hover .toggle-switch:before {background: linear-gradient(to bottom, #fff 0%, #fff 100%);box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);}
.toggle-checkbox:checked + .toggle-switch {background: #005FFF;}
.toggle-checkbox:checked + .toggle-switch:before {left: 30px; content: '';}
.toggle-checkbox {position: absolute;visibility: hidden;}
.toggle-label {margin-left: 15px;position: relative;top: 2px; display: flex; align-items: center;}

.acboxs table .form-control{ min-width: 200px;}
.scrolldiv.studentscroll {    max-height: calc(100vh - 140px);  }
.alert-top{ position: absolute; left: 0px; top: 0px; width: 100%;}
.pageheader{position: relative;z-index: 9;}
.pageheader h4 a{transition: all ease 0.75s;}
.pageheader h4 a:hover{ color: #005FFF !important;}

.albox {display: flex;flex: 1; flex-direction: column;}
.albox-titbtn{ display: flex; margin-bottom: 15px;}
.albox-tit, .albox-btn{ display: flex; flex:1}
.albox-btn{ justify-content: flex-end;}
.albox-tit{ flex-direction: column;}
.albox-tit h4 {border-bottom: 1px solid #414141;width: 100%;margin-bottom: 0px;}
.altermsbox{ position: relative; margin-bottom: 30px;}
.altcont{ padding-left: 60px; overflow-x: auto; -webkit-overflow-scrolling: touch;}
.altcont .backgroundblur{ display: flex; padding: 15px; flex-direction: column;}
.altcontaction{ width: 100px; display: flex; height:100%;}
.altcontbox{ width: 100%;}
.altcontbox h4{ color: #005FFF; text-transform: uppercase;}
.abunobt{display: flex; align-items: center;}
.abunobt .btngrouprht{ margin: 0px 15px;}
.altermsbox .table .btngrouprht{ min-width: auto;} 

.altermsbox  .table{height: 100%;  border-radius: 27px;   box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15);  background: linear-gradient(to top, rgba(255,255,255, 0.4), rgba(255,255,255, 0.1));
backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); }
.altermsbox .table tr:hover {background: #e5edfd;}  
.altem {position: absolute;left:-88px;top:30px;  background: #e3edff;border-radius: 0px 0px 10px 10px;padding: 10px;transform: rotate(90deg); box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15);}
.altem .btngrouprht {min-width: auto;}
.altem .btngrouprht .eva{ transform: rotate(-90deg) !important;}
.albox-tit label {font-size: 16px;margin-bottom: 10px; color: #005FFF;}
.albox-tit input{ padding: 10px 10px; border: none; border-bottom: 1px solid #414141; border-radius: 0px;}
.albox-tit input:hover, .albox-tit input:focus{border: none; border-bottom:1px solid #005FFF;}

.altem [data-tooltip]::before{ display: none;}
.altem [data-tooltip]::after{transform:rotate(-90deg); top: 0px; left: 25px;}

.iframe.verifyiframe {min-height: 700px;}

.logtxtbox { padding: 40px; position: absolute;left: 50%;top: 50%;width: 85%; height: 35rem;display: flex;align-items: center;justify-content: center;border:1px solid rgba(255, 255, 255, 0.3);background: rgba(255, 255, 255, 0.1);transform: translate(-50%, -50%);}
.logtxtbox h1 {font-weight: bold;font-size: 65px; color: #fff;}
.dartxtclr{ color: #14144d;}
.logouter{ padding: 40px; background: #f6faff; display: flex; align-items: center; justify-content: center;}
.logouter .logincon{ width: 75%; overflow: hidden; border: 1px solid #e6ecf7}

.splitortxt{ position: relative; display: flex; align-items: center; justify-content: center;}
.splitortxt::before{content: ''; position: absolute; left: 0px; width: 100%; top: 50%; transform: translateY(-50%); height: 1px; background: #e6ecf7;}
.splitortxt span {position: relative;background: white;width: 40px;text-align: center;}
.text-underline{ text-decoration: underline;}
.loglinks{ color: #000032; display: inline-flex; align-items: center;}
.loglinks:hover{color: #005FFF;}
.loglinks svg.eva{ margin-right: 5px;}
svg.eva{ transition: all ease 0.75s;}
.loglinks:hover svg{ fill: #005FFF;}
.form-control.dang, .form-control.dang:focus, .form-control.dang:hover {box-shadow: 0px 0px 10px rgba(255, 2, 2, 0.9) !important; border-color: red !important;}
.cf-alert{position: fixed;top: -250px; min-height: 60px; overflow: hidden; left: 50%; z-index: 99999; transform: translateX(-50%) !important;  transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94); border: 1px solid transparent; width: auto; border-radius: 0px 0px 6px 6px ; display: flex; align-items: center; padding: 10px 15px;}
.react-tel-input .form-control{ width: 100%; border-radius: 10px; border-color: #e5e3ef;}
.react-tel-input .form-control:hover{border-color: #005FFF;}
.cf-alert.show{ top: 0px; font-weight: 600; overflow: visible;}
.alert-success{box-shadow: 0px 5px 10px rgba(43, 124, 76, 0.1); border-color: #a7dbbb; background: #eefff4; }
.alert-primary{box-shadow:0px 5px 10px rgba(0, 95, 255, 0.1);border-color: #bed6ff;background: #eef4ff; color: #0062ff;}
.alert-danger{box-shadow: 0px 5px 10px rgba(175, 35, 58, 0.1); border-color:#ecb0ba; background: #ffeef1;}
.fileslist{display: flex; flex-direction: column;}
.filestit{ display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 30px; min-height: 45px;}
.filestit h4{ margin: 0px;}
.filesli{ display: fle; flex-direction: column;}
.filesli ul {background: #fff;padding: 10px;border-radius: 10px;box-shadow: 0px 1px 3px rgba(0,0,0,0.1); max-height: calc(100vh - 285px);overflow: hidden;overflow-y: auto;}
.filesli ul li{ transition: all ease 0.75s; display: flex; flex-direction: row; border: 1px solid #E3EDFF; align-items: center; background: #F4F8FF; padding: 10px; border-radius: 10px; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);  flex-wrap: wrap; justify-content: flex-end;}
.filesli ul li:nth-child(odd){background: #fff;}
.filesli ul li:hover{background: #E3EDFF;}
.filesli ul li:not(:last-child){ margin-bottom: 10px;}
.cl-listimg{ min-width: 30px; min-height: 30px; margin-right: 10px; position: relative;}
.cl-listimg img{ height: 30px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.cs-listcon{ display: flex; flex: 1;  flex-direction: column; align-items: flex-start; margin-right: 15px;}
.cs-listcon .badge{ margin-top: 5px; font-weight: 600; text-transform: uppercase;    letter-spacing: 1px; font-size: 9px;}
.filestit .row {width: 100%;}
.cl-listuser{ border-radius: 50%; overflow: hidden;}
.cl-listuser img{}
.cs-listtit{ font-weight: 600;}
.cl-badges{ display: flex; align-items: center; justify-content: space-between;}
.cl-badges .btn-action{ position: relative;}
.cl-badges .btn-action:last-child::after {left: auto;right: 0px;transform: translate(0%);}
.cl-badges .btn-action:not(:last-child), .cs-badges .badge-info:not(:last-child){ margin-right: 10px;}
.cl-icocount{transition: all ease 0.75s; z-index: 1; position: absolute; top: -10px; border:1px solid #DAE8FF; right: -5px;background: #edf3ff;border-radius: 6px; font-size: 9px;display: flex;align-items: center;justify-content: center; padding: 0px 3px; color: #464646; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);}
.cs-badges{ display: flex; flex: 5;}
.cs-badges .badge-info{ display: inline-flex; width: auto; align-items: center; background-color: #f4f8ff; color: #464646; box-shadow: 0px 0px 2px rgba(0,0,0,0.2); margin-right: 10px; transition: all ease 0.75s;}
.cs-badges .badge-info:hover{ box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);}
.cs-badges .badge-info .badge-cls{ margin-left: 5px; color: #aaa; cursor: pointer; transition: all ease 0.75s;}
.cs-badges .badge-info .badge-cls:hover{color: #000;}
.cs-badges .badge-info .badge-con{width: 110px; text-overflow: ellipsis; overflow: hidden; padding: 2px 0px; }
.cs-fileupload .sc-dmqHEX{flex-grow: inherit;flex-direction: column; align-items: center;}
.cs-fileupload .sc-dmqHEX > span {font-size: 14px;color: rgb(80, 96, 125); }
.cs-fileupload .sc-beqWaB svg{ display: none;}
.cs-fileupload .sc-dmqHEX .file-types{ max-width: 100%;}
.cs-fileupload .sc-beqWaB{border: 2px dashed rgb(2, 95, 255); min-height: 325px;padding: 30px; margin-bottom: 30px; flex-direction: column; justify-content: center; max-width: 100%; margin: 0px;}
.cs-fileupload h5{ line-height: 40px; padding: 0px 50px; margin: 0px;}
.createfile {height: calc(100vh - 200px);}
.cllcimg{ display: flex; flex: 1;}

.cs-mention{display: flex; position: relative;}
.cs-meditor{ flex: 1; display: flex; flex-direction: column;}
.cs-mrhtsidebar{  margin-left: 30px; display: flex; flex-direction: column; position: relative;  border-radius: 20px; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15);  background: rgb(255 255 255 / 50%);  padding: 15px 1px; }
.cs-mrhtsidebar .tableblur{width: 0px; overflow: hidden;  transition: all ease 0.75s;  background: transparent; border-radius: 0; box-shadow: none;  padding: 0px;}
.cs-mrhtsidebar.show .tableblur{width: 300px; overflow: visible; }
.cs-mrhtsidebar.show{padding: 15px;}
.cs-sharrow {position: absolute;left: -20px;top: 20px;width: 20px;display: flex;align-items: center;justify-content: center;height: 50px;border-radius: 10px 0px 0px 10px;cursor: pointer; box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: rgba(255,255,255, 0.4); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px);}
.cs-sharrow:hover svg{ fill: #fff;} 
.cs-sharrow:hover{ background: #005FFF;} 
.cs-sharrow .show{ display: block;}
.cs-sharrow .hide{ display: none;}

.cs-mention .tableblur{height: 100%;}
.bt-select{appearance: none; padding-right: 48px; background-repeat:  no-repeat !important; background-position: top right !important; background-image:url('../images/icons/chevron-down-w.png') , linear-gradient(to top, #005FFF 75%, #005FFF 95%); margin-bottom: 15px;}
.bt-select:hover{background-image:url('../images/icons/chevron-down.png') , linear-gradient(to bottom, #fff 0%, #e8e8e8 100%) !important;}
.cs-mrhtstab .nav-tabs li{width: 38%; text-align: center; }
.cs-mrhtstab .nav-tabs li:last-child{width: 24%;}
.nav-tabs .nav-link{ transition: all ease 0.75s; padding: 15px 10px;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{ color: #005FFF; border-color: #005FFF;}
.csms-attributes {height: calc(100vh - 362px);overflow-y: auto;}
.csms-attributessign{height: calc(100vh - 420px);}
.csms-attributes ul li{ display: flex; flex-direction: column; margin-bottom: 15px; cursor: pointer; transition: all ease 0.75s; border-radius: 6px; background: #fff; padding: 10px; box-shadow:0 2px 3px rgba(0,0,0,0.1);  }
.csms-attributes ul li:hover{ background: #e3edff;}
.attribname{ color: #005eff; }
.attribname svg{ width: 18px; margin-right: 10px; fill: #005eff;}
.attribcont{ color: #464646; padding-left: 28px;}
.attribcont p{ margin-bottom: 0px; font-weight: 500;}

.droparea {margin-right: 15px;border-radius: 10px;padding: 5px;text-align: center; transition: all ease 0.75s; width: 100%;}
/* .droparea:hover{background: #fff;}  */
.dafhtxt{ font-size: 11px; font-weight: 500; padding: 5px;}
.droparea .badge{background: #fff;}
.droparea .badge:not(:last-child) { margin-bottom: 5px;}
/* .droparea .badge:nth-child(2n+1) {margin-left: 5px;} */

.modal-body .btngrouprht{ display: flex; flex-direction: column; align-items: center;}
.modal-body .btngrouprht .btn {min-width: 210px; margin: 0px; justify-content: space-between;}
.modal-body .btngrouprht .btn:not(:last-child){ margin: 0px 0px 15px 0px;}
.mcls {opacity: 0.3; position: absolute;top: -15px; right: -15px;width: 30px;height: 30px;border-radius: 10px;display: flex;align-items: center;justify-content: center;font-size: 20px;border: 1px solid #ddd;background: #fff;cursor: pointer; transition: all ease 0.75s; z-index: -1;}
.mcls:hover{opacity:1; z-index: 1;}
.modal-content{ border-radius: 10px;background: rgba(255, 255, 255, 0.1) url('../images/a.png') no-repeat;  background-size: cover; padding: 15px; backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15);}
.modal-body{ background: #fff url('../images/a.png') no-repeat; background-position: 0px 50%; border-radius: 10px; background-size: cover;}
.list-modal{ max-height:calc(100vh - 500px) !important; overflow-y:auto;}
.minw-auto{min-width: auto !important;}

.bloz0n9{ line-height: normal; display: flex; align-items: center;}

body::-webkit-scrollbar { width: 1em;} 
body::-webkit-scrollbar-track {-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);}
body::-webkit-scrollbar-thumb {background-color: darkgrey; outline: 1px solid slategrey;}

.form-group .react-tel-input .flag-dropdown, .react-tel-input .flag-dropdown.open .selected-flag{ top: 1px ;left: 1px; border-radius: 10px 0 0 10px; height: 96%; border: none; border-right: 1px solid #e5e3ef;}
.mnw6qvm{ padding: 5px !important; max-height: 400px; overflow-y: auto; border-radius: 10px;}
.cusmention{ display: flex; padding: 5px 10px; transition: all ease 0.75s; border-radius: 10px;}
.cusmention:hover{box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 3px 5px rgba(0,0,0,0.15); background: #005fff; color: #fff;}
.cusmention svg{ fill: #005fff;}
.cusmention:hover svg{ fill: #fff;}
.cusmention > div{ display: flex; height: 28px; margin-right: 10px; align-items: center; justify-content: center; color: #005fff;}
.cusmention > div + div{ display: flex; flex: 1; flex-direction: column; align-items: flex-start; height: auto; font-weight: 600;}
.cusmention > div + div div:nth-child(2){ margin: 0px; font-weight: normal; color: #66738a; transition: all ease 0.75s;}
.cusmention:hover > div, .cusmention:hover > div + div div:nth-child(2){ color: #fff;}
.myz2dw1, .mtiwdxc{ padding: 0px;}

.draggableDiv li > div {width: 100%;display: flex;}
.draggableDiv li { cursor: pointer;}

.filesli ul li ul, .filesli ul li ul li {background: transparent !important;border: none !important;padding: 0px !important;box-shadow: none !important; margin-bottom: 0px !important; overflow: visible;}
.filesli ul li .foldpics {display: flex;margin-right: 10px;}

.filesli ul li .foldpics .flpic { width: 30px;height: 30px; font-size: 10px;}
.filesli ul li .foldpics li:not(:first-child) {margin-left: -15px;}

.canvas-container .canvas{border: 1px solid #ccc;margin-bottom: 20px; width: 100%; background: #fff; }

.editor-alignment-center div{text-align: center; word-break: break-word;}
.editor-alignment-right div{text-align: right; word-break: break-word;}
.editor-alignment-left div{text-align: left; word-break: break-word;}

.dragging {cursor: grabbing}
.cursor-pointer {cursor: pointer}
.react-tel-input .selected-flag{ border-radius: 10px 0 0 10px;}

.altermslists{ display: flex; position: relative; flex: 1;}

table{font-size: 14px !important;}
.dl-status, .dl-date{ display: flex;flex-direction: column; padding: 0px !important; align-items: flex-start; position: relative;   min-height: 115px; justify-content: center;}
.dl-status span.badge{ margin-bottom: 5px; text-transform: uppercase;}
.dl-ago{ font-size: 12px; color: #687a99;}

.sboverlay {position: fixed;right: 0;top: 0;width: 0%;height: 100%;background: rgba(255, 255, 255, 0.1);z-index: 8;backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); transition: all ease 0.75s;}
.sboverlay.show{width: 100%;}

.sboverlay{ display: none;}
.mandatory{  width: 100%;  margin-top: .25rem;  font-size: 80%;  color: #dc3545;  }
.mandatory-req{  width: 100%;  margin-top: .25rem;  font-size: 80%;  color: #005fff;  }

.modal{ animation: fadeIn 0.2s ease-in-out;}

.table-responsive{ overflow: hidden; overflow-x: auto; padding-bottom: 30px;}

.minh-auto {min-height: auto !important;}
.form-control.hl-vip {min-height: 60px;}

.sidebarswitch{ margin-bottom: 15px;}
.sidebarswitch .badge{ display: flex; flex: 1; cursor: pointer; border-radius: 10px;}
.sidebarswitch .badge h6{ margin-bottom: 0px; display: flex; align-items: center; justify-content: center; flex: 1; margin-left: 0px;}
.sidebarswitch .badge .darw{ width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%234f4f4f%27%3E%3Cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z%27/%3E%3C/svg%3E") no-repeat; background-size: 1.25rem; background-position: center;}
.sidebarswitch .dropdown-menu{ width: 100%; border-radius: 0px 0px 15px 15px; overflow: visible; z-index: 9; background: #e7f0ff;}
.sidebarswitch .badge[aria-expanded="true"], .sidebarswitch:hover .badge{background: #fff;border-radius: 15px 15px 0px 0px;box-shadow: 0px 0px 3px rgba(0,0,0,0.1); position: relative; z-index: 10;}
.sidebarswitch .dropdown-menu > li{position: relative;}
.sidebarswitch .dropdown-menu > li:first-child a{ border-radius: 0px;}
.sidebarswitch .dropdown-menu > li span{ margin: 0px; text-overflow: ellipsis;  overflow: hidden;}
.sidebarswitch .dropdown-menu > li span:hover{ color: #005fff; background: #fff;}
.sidebarswitch:hover .dropdown-menu, .sidebarswitch .dropdown-menu.show {display: block !important;}


.profile-area p span.username{margin-left: 5px; position: relative;}
.profile-area p span.username, .sidebarswitch .badge h6, .sidebarswitch .dropdown-menu > li a {text-overflow: ellipsis; max-width: 200px; display: inline-block; justify-content: flex-start; overflow: hidden;white-space: nowrap;  }
.sidebarswitch .badge h6, .sidebarswitch .dropdown-menu > li a{ max-width: 100%; font-size: 12px;font-weight: bold; display: flex;}
.sidebarswitch [data-tooltip]::after {width: auto;white-space: normal;line-height: 20px;min-width: auto;word-break: break-word;top: auto;bottom: calc(100% + 5px);max-width: 100px;}
.sidebarswitch [data-tooltip]::before{bottom:100%; top: auto; border-color:  transparent #fff transparent transparent; z-index: 10;}
.chcro .svgicon{width: 35px; height: 25px;}
.chcro svg {width: 25px; display:  inline-block;}
.chcro .path {stroke-dasharray: 1000;stroke-dashoffset: 0; }
.chcro .path.circle {-webkit-animation: dash 0.9s ease-in-out;animation: dash 0.9s ease-in-out; }
.chcro .path.line {stroke-dashoffset: 1000;-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;animation: dash 0.9s 0.35s ease-in-out forwards;}
.chcro .path.check {stroke-dashoffset: -100;-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;animation: dash-check 0.9s 0.35s ease-in-out forwards;}

.alertmsg{display: flex; flex: 1;}
.chcro .btn-link {padding: 0px !important;color: #005eff;text-decoration: underline;height: auto;box-shadow: none !important; background: transparent !important;}
.chcro .btn-link:hover{ color: #464646;}
.popcontent{ line-height: 30px; font-size: 18px;}

@-webkit-keyframes dash {
0% {stroke-dashoffset: 1000;}
100% {stroke-dashoffset: 0;}
}
@keyframes dash {
0% {stroke-dashoffset: 1000;}
100% {stroke-dashoffset: 0;}
}
@-webkit-keyframes dash-check {
0% {stroke-dashoffset: -100;}
100% {stroke-dashoffset: 900;}
}
@keyframes dash-check {
0% {stroke-dashoffset: -100;}
100% {stroke-dashoffset: 900;}
}

.nocertimg{ display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 50px 0px; text-transform: uppercase; font-weight: 600; opacity: 0.5;}
.nocertimg img{ width: 100px; margin-bottom: 15px;}
.icondditem{align-items: center; box-shadow:none !important;  background: #fff !important; color: #005eff !important; text-transform: uppercase; font-weight: 600 !important; display: inline-flex !important;   border-radius: 0px 0px 10px 10px !important;}
.icondditem svg{ width: 20px; margin-right: 5px; fill: #005eff !important;}
.customize-template .formscroldiv {height: calc(100vh - 260px);}
.w-100px{ width: 100px;}
.w-75px{ width: 75px;}

.text-link{ color: #005FFF;text-decoration: underline;transition: all ease 0.75s;}
.text-link:hover{ color: #333;text-decoration: none;}
.text-sm{font-size: 12px;}
.verify svg{ margin: 0px;}




@keyframes circle {
  from {-webkit-transform: rotateZ(0deg)}
  to {-webkit-transform: rotateZ(360deg)}
}

@keyframes ccircle {
  from {-webkit-transform: rotateZ(360deg)}
  to {-webkit-transform: rotateZ(0deg)}
}

.cumsoontxt {position: absolute;right: 10px;top: 10px;padding: 4px 10px;background: rgba(208, 225, 255, 0.9);z-index: 2;font-size: 10px;border-radius: 5px;font-weight: bold;color: #325ea6;}

.cs-customcert{ margin-left: 0px; margin-right: 30px;}
.cs-customcert .tableblur {height: calc(100vh - 212px);  overflow-y: auto !important;}
.cs-previewcus img{ max-width: 100%; max-height: 650px; margin: 0px auto; display: block;}

.bluetheme{background: linear-gradient(to top, #005FFF 75%, #005FFF 95%); box-shadow: inset 0 0 30px 0 rgba(255,255,255,0.35), 0 2px 5px rgba(0,0,0,0.25); color: #fff !important;}
.bluetheme svg{ fill: #fff !important;}

.bluetheme:hover{background: linear-gradient(to bottom, #fff 0%, #e8e8e8 100%); color: #005FFF !important;}
.bluetheme:hover .eva{fill: #005FFF !important;}

.ddspbadge .badge{ background: #fff; font-size: 12px; color: #000; padding: 10px 15px; margin-bottom: 15px; cursor: grab; border: 1px solid #ddd; transition: all ease 0.75s;}
.ddspbadge .badge:not(:last-child){ margin-right: 15px;}
.ddspbadge .badge:hover{background: #e3edff; color: #005eff; border-color: #005eff;}

.btn-icon img {filter: brightness(0) invert(1); width: 16px; margin-right: 5px; transition: all ease 0.75s;}
.btn-icon:hover img{ filter: none;}
.btn-action img { max-width: 65%; max-height: 65%; transition: all ease 0.75s;}
.btn-action:hover img{filter: brightness(0) invert(1);}

.filter-none img {filter: none !important; width: 30px; margin: 0px;}


.toggleSwitch span span, .toggleSwitch > span::before {display: none;}
.toggleSwitch {display: inline-block;height: 18px;position: relative;overflow: visible;padding: 0; cursor: pointer;width: 40px; z-index: 0;}
.toggleSwitch * {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
.toggleSwitch label,.toggleSwitch > span {line-height: 20px;height: 20px;vertical-align: middle;}
.toggleSwitch input:focus ~ a,.toggleSwitch input:focus + label {outline: none;}
.toggleSwitch label {position: relative;z-index: 3;display: block;width: 100%;}
.toggleSwitch input {position: absolute;opacity: 0;z-index: 5; }
.toggleSwitch > span {position: absolute;left: -50px;width: 100%;margin: 0;padding-right: 50px;text-align: left;white-space: nowrap;}
.toggleSwitch > span span {position: absolute;top: 0;left: 0;z-index: 5;display: block;width: 50%;margin-left: 50px;text-align: left;font-size: 0.9em;width: 100%;left: 15%;top: -1px;opacity: 0;}
.toggleSwitch a {top: 0px; position: absolute;right: 50%;z-index: 4;display: block;height: 25px;padding: 0;left: 3px;width: 18px;background-color: #e1e1e1; border-radius: 100%;-webkit-transition: all 0.2s ease-out;-moz-transition: all 0.2s ease-out;transition: all 0.2s ease-out;box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);}
.toggleSwitch > span span:first-of-type {color: #ccc;opacity: 1;left: 45%;}
.toggleSwitch > span:before {content: '' !important;display: block;width: 100%;height: 100%;position: absolute;left: 50px;top: -2px;background-color: #fff;border: 1px solid #e5e3ef;border-radius: 30px;-webkit-transition: all 0.2s ease-out;-moz-transition: all 0.2s ease-out;transition: all 0.2s ease-out;}
.toggleSwitch input:checked ~ a {border-color: #fff;left: 100%;margin-left: -8px;}
.toggleSwitch input:checked ~ span:before {border-color: #B0C9F0;box-shadow: inset 0 0 0 30px #CDE0FF; background: #CDE0FF;}
.toggleSwitch input:checked ~ span span:first-of-type {opacity: 0;}
.toggleSwitch input:checked ~ span span:last-of-type {opacity: 1;color: #fff;}
.toggleSwitch.large {width: 50px; height: 27px;}
.toggleSwitch.large a {width: 25px;}
.toggleSwitch.large > span {height: 29px;line-height: 28px;}
.toggleSwitch.large input:checked ~ a {left: 30px; background: #005FFF;}
.toggleSwitch.large > span span {font-size: 1.1em;}
.toggleSwitch.large > span span:first-of-type {left: 50%;}
.mw-300{max-width: 300px;}
.cs-plan{display: flex; flex-direction: column; padding: 15px; border: 1px solid #eee; border-radius: 20px; background: #fff; margin-bottom: 15px; min-height: 100px; justify-content: center;}
.csplan-name{color: #005eff; font-weight: 600; margin-bottom: 0px; font-size: 25px;}
.csplan-name span{ margin-left: 5px;}
.csplan-price span{margin-left: 5px; font-size: 12px;}
.cs-plancard{ flex-direction: row; align-items: center;}
.cardtype, .changecard{ width:100px;}
.carddetail{ flex: 1; display: flex; flex-direction: column;} 
.badge-primary svg{ fill: #005eff !important; width: 15px;}
.smallflow{ font-size: 15px; margin: 0px !important; color: #333;}
.ctcustomized .cs-mrhtsidebar {margin-left: 0px;margin-right: 30px;}
.ctcustomized .cs-sharrow{ left: auto; right: -20px; border-radius: 0px 10px 10px 0px;}
.ctcustomized .cs-mrhtsidebar .tableblur {height: calc(100vh - 180px);   overflow: hidden; overflow-y: auto;} 

.cly-selectD{ display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom: 30px; }
.cly-selectD .cly-doc{ border: 1px solid #e5e3ef; background: #fff; width: 150px; height: 200px;  display: flex; align-items: center;  justify-content: center; margin-bottom: 15px; overflow: hidden;}
.cly-selectD .cly-doc img{ max-width: 100%;}
.cly-selectD .cly-doc svg{ fill: #939393; width: 50px; height: 50px;}
.cly-selectD .cly-docont{ text-align: center; transition: all ease 0.75s; overflow: hidden;  white-space: nowrap;  text-overflow: ellipsis;  display: block;  width: 100%;}
.cly-selDC{ display:flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; width: 100%; padding: 30px;  transition: all ease 0.75s;}
.cly-selDC:hover .cly-docont{ color: #005eff;}
.cly-selDC:hover{background: #cadeff;}
.tickettemp .cly-doc{ height: auto;}
.cly-proimg{ width: 100%; background: #fff; padding: 30px; border-radius: 27px; border: 1px solid #e5e3ef;}
.cly-proimg .cly-pimg{ width: 200px;height: 200px; border-radius: 50%;position: relative; overflow: hidden; background:#cadeff; margin: 0px auto; margin-bottom: 15px; border: 5px solid #fff; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);}
.cly-proimg .cly-pimg img{ position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); height: 225px; }
.cly-proimg .cly-pimg .cly-upimg{ position: absolute; bottom: 0px; left: 0%; width:100%; height: 100%; box-shadow:inset 0 0 30px 0 rgb(255, 255, 255), 0 3px 5px rgb(255, 255, 255); background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)); backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); cursor: pointer; opacity: 0; transition: all ease 0.75s; display: flex; align-items: center; justify-content: center; flex-direction: column;}
.cly-proimg .cly-pimg:hover .cly-upimg{opacity: 1;}
.cly-proimg .cly-pimg .cly-upimg svg {height: 62px;width: 62px;fill: #fff;}
.cly-proimg .cly-pimg .cly-upimg p{ text-transform: uppercase; color: #fff; font-weight: 500;}
.cly-proimg p span{ font-weight: 600;}
.instletter {width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 7em;color: #7eaeff;}

.cly-cusel .css-13cymwt-control, .cly-cusel .css-t3ipsp-control {border-color: #e5e3ef;border-radius: 10px;height: 48px !important;transition: all ease 0.75s;font-size: 13px;color: #000;}

.cly-cusel .css-t3ipsp-control{border-color: #005eff !important; border-width: 1px; box-shadow: none !important;}

@media (min-width: 992px) {
main {padding-left: 250px;}
.mobile-header{padding: 0px;}
}

@media (max-width: 1600px) {
.logouter .logincon {width: 100%;}
}
@media (max-width: 1440px) {
.folder::before{width: 170px;}
.folder::after{left: 143px;}
.iframe.verifyiframe {min-height: 550px;}
}

@media (max-width: 1366px) {
  body{overflow-x: hidden;}
html, body, div#root, .dashboard-light-bg, .h100vh, .backgroundblur{height: 100%;}
.commonbox {padding-bottom: 15px;}
#main-navbar{ display: flex;}
.sidebar {width: 0px; height: calc(100vh - 30px); transition: all ease 0.75s; overflow: hidden; left: auto; right: 0px; transform: translateX(100%); box-shadow: 0 3px 5px rgba(0,0,0,.35) !important; overflow-y: auto; border-radius: 0px 0px 16px !important; background: #fff !important;}
.sidebar.show{width: 280px; transform: translateX(0);}
.sidemenupanel .list-group-item{white-space: nowrap;}
#sidebarMenu .sidebarlogo { display: none;}
.sidebar .list-group{ padding-top: 48px; margin: 0px;}
main{padding-top: 48px; padding-left: 0;} 
main.height100per .container-fluid {padding-left: 15px !important; padding-top: 15px !important;}
.scrolldiv{ padding: 0px; height: calc(100vh - 110px);}
.mobvis{ display: inline-flex !important;}
.content-header{ display: none;}
.mobileprofile{ display: flex; justify-content: center; margin-bottom: 10px;}
.formscroldiv {height: calc(100vh - 320px);}
.mar-top {margin-top: 0;}
.sidebarbottom{ margin-top: 0px;}
.verifycontent .col-md-6.offset-md-3 {width: 100%;margin: 0px;}
.iframe.verifyiframe {min-height: 700px;}
.loginform { width: 550px;}
.createfile{height: calc(100vh - 225px);}
.sboverlay{ display: block;}
}
@media (max-width: 1280px) {
.logtxtbox h1{font-size: 60px;}
}

@media (max-width: 1199px) {
.studentcert .ctemp .backgroundblur iframe, .iframe { min-height: 450px;}
.loginform {width: 440px;padding: 30px 75px;}
.loginform .main-content {width: 100%;}
.logtxtbox{padding: 30px; height: 25rem;}
.logtxtbox h1{ font-size: 40px;}
.foldersview .row{overflow: hidden; overflow-x: auto; flex-wrap: nowrap;}
.foldersview .row .col-md-4{ width: 33.33%; margin-bottom: 30px;}
.foldersview .row .col-md-9{ width: 900px;}
.mintnft .ctemp .backgroundblur h3 {font-size: 18px;}
.mintnft .ctemp .backgroundblur h4 {font-size: 16px;}
.foldersview .row .col-md-3, .dashlast .col-md-3, .dashlast .col-md-9, .dashlast  .col-md-6{ width: 100%;}
.barchart{ margin: 30px 0px;}
.dashlast h5 br{ display: none;}
.formscroldiv{height: calc(100vh - 325px); margin-bottom: 15px;}
.txtsfont .col-md-8, .txtsfont .col-md-4, .txtsstyle .col-md-6 {width: 100%;}
.txtsstyle .col-md-2 {width: 33.3%;}
.txtsstyle .col-md-4 {width: 66.6%;}
.studentcert .ctemp .backgroundblur iframe, .iframe {min-height: 375px;}
.createfile{height: calc(100vh - 220px);}
.foldersview .row{ flex-wrap: wrap;}
.foldersview .row .row{ flex-wrap: nowrap;}
.foldersview .row .col-md-9{ width: 100%; margin-top: 30px;}
.logtxtbox h1 {font-size: 45px;}
.draggableDiv li > div{ flex-wrap: wrap;}
.cllcimg {flex: 0 0 100%; width: 100%; margin-bottom: 15px;}
.cs-listcon{ margin-right: 0px;}
}

@media (max-width: 991px) {
.createfile .tableblur,   .container-fluid.ptb15.h100vh  {padding: 0px;}
.container-fluid.ptb15.h100vh > .commonbox {margin: 0px; border-radius: 0px;} 
.sidebar{ height: 100vh; border-radius: 0 !important;}
.foldpics{ bottom: 0; opacity: 1;}
.abunobt {align-items: flex-start;flex-direction: column;}
.abunobt .btngrouprht {margin: 15px 0px 15px;}
.albox-titbtn, .altcont .backgroundblur{ flex-direction: column; }
.albox-tit{ margin-bottom: 15px;}
.altcontaction {width: 100%;align-items: center;justify-content: center;padding: 10px;background: #deeaff;border-radius: 27px;}
.altcontaction .btngrouprht {min-width: 100%;text-align: center;}
.cs-badges{ width: 100%; flex: 0 0 100%; margin-top: 10px; justify-content: flex-end; order: 4;}
.cs-badges .droparea{ margin-right: 0px;}
.cs-badges .badge-info:not(:first-child){ margin-right: 0px;}
.loginimg{ display: none;}
.logouter .logincon {width: auto;}
.loginform{padding: 30px 40px;}
.loginform .title{ font-size: 30px;}
.main-logo{ margin-bottom: 30px;}
.accessToken .form-control {min-width: 400px;}
.cs-mrhtsidebar{position: absolute; right: 0px; top: 0px; }
.cs-mrhtsidebar.show .tableblur {background: #fff;}
.mobnav .cmpname{ width: 30em;}
.cs-fileupload{ padding: 15px !important;}
.ctcustomized .cs-mrhtsidebar{ right: auto; left: 0px; z-index: 1;}
}

@media (max-width: 767px) {
  .cf-alert{ min-height: 48px; padding: 5px 10px;}
.loginimg{ display: none;}
.scrolldiv {height: calc(100vh - 110px);} 
.createcetr h4{ margin-bottom: 15px;}
.mobnav .cmpname h5{ font-size: 18px; }
.searchform{ flex-wrap: wrap; }
.tableblur .searchform .fields:first-child {flex: 0 auto; width: 100%; margin: 0px 0px 15px 0px;}
.tableblur .searchform .fields:not(:first-child){ width: 50%; flex: 0 auto; margin: 0px; margin-bottom: 15px;}
.tableblur .searchform .fields:nth-child(2), .tableblur .searchform .fields:nth-child(4){ padding-right: 15px;}
.tableblur .searchform .fields:nth-child(3), .tableblur .searchform .fields:nth-child(5){ padding-left: 15px;}
.certsteps div, .certsteps a{font-size: 11px !important;}
.searchform .fields.txtfields {flex: auto;width: 100%; margin-bottom: 15px;}
.searchform .fields.txtfields + .fields {margin: 0px;width: 100%;flex: auto;}
.custable {font-size: 12px;}
.listview .accordion-body img {    max-height: 100%;max-width: 100%;}
.listviewtxts{font-size: 12px; font-weight: bold;}
.studbetch {width: 50px;}
.studid {width: 75px;}
.verifytable {margin-top: 15px; text-align: center;}
.verifytable .otp-input-fields{ margin: 0px auto; padding: 10px 5px; gap: 5px; }
.verifytable .otp-input-fields input{width: 30px;}
.verifytable tr:nth-child(even) {background: #fff;}
.verifytable tr:nth-child(odd) {background: #dde9ff;}
.verifytable td {display: block;}
.pageheader .col-md-4{text-align: center; margin-bottom: 15px;}
/* .pageheader h4, .pageheader h6{text-align: center;} */
.pageheader h6{ font-size: 12px;}
/* .pageheader .btnwithpro{justify-content: center;} */
.pageheader{ z-index: 1;}
.row .table tr td, .row .table tr th {white-space: nowrap;}
.altcontbox{text-align: center;}
.abunobt {    align-items: center;}
.loginform {width: 100%;}
.main-logo{justify-content: center;}
.loginform .title, .loginform .title + p{text-align: center;}
.filesli ul{ margin-bottom: 30px;}
.createfile {height: calc(100vh - 235px);}
.cs-fileupload .ewqTBN{ margin-bottom: 30px;}
.cs-fileupload h5 {line-height: 30px; font-size: 12px; padding: 0px; }
.foldersview .row .col-md-4{ width: 300px;}
}

@media (max-width:575px) {
.mintnft .ctemp .backgroundblur h3 {font-size: 14px;}
.mintnft .ctemp .backgroundblur h4 {font-size: 12px;}
.mintnft .img img{max-height: 46px;}
.mintnft .ctemp .backgroundblur{min-height: 160px;}
.ctemp .img img { max-height: inherit;}
.vcheader .col-sm-6{ text-align: center !important;}
.vtlinks{ align-items: center; justify-content: center; margin-top: 5px;}
.vfpdfcont .col-sm-4, .vfpdfcont .col-sm-4 p{ text-align: center !important; margin-bottom: 15px;}
.loginform {padding: 30px 50px; }
.cs-fileupload h5{ padding: 0px;}
.cs-listtit{ font-size: 12px;}
.mobnav .cmpname{ width:260px;}
}

@media (max-width:530px) {
.verifyotp .inputbtngroup{flex-direction: column; width: 100% !important;}
.verifyotp .inputbtngroup .otp-input-fields{ flex: 1; max-width: 100%; border-radius: 10px 10px 0px 0px !important; padding: 15px;}
.verifyotp .inputbtngroup .btn{border-radius: 0px 0px 10px 10px !important; margin: 0px;}
.otp-input-fields input{width: 30px;}
.abunobt .btngrouprht .btn{margin:0px 15px 15px; display: inline-block;}

} 

@media (max-width:475px) {
.bluetxttitle, .dashlast h5, .createcetr h5{font-size: 16px;}
.createcetr h4{font-size: 20px;}
.teammemberprofile{width: 40px; height: 40px;}
.icontext .text{font-size: 12px;}
.viewall .icon{width: 20px; height: 20px;}
.recharts-surface tspan{font-size: 8px;}
.mintnft .img img{max-height: 35px;}
.tableblur .searchform .fields:not(:first-child){width: 100%; flex: 0 auto; margin: 0px; margin-bottom: 15px; padding-left: 0px; padding-right: 0px;}
.listviewtxts{flex-wrap: wrap;}
.listviewtxts span{width: 100%;flex: auto;}
.listviewtxts span:not(:last-child){ margin-bottom: 10px;}
.studentcert .ctemp .backgroundblur iframe, .iframe{min-height: 300px;}
.logouter{padding: 15px;}    
.loginform{padding-left: 20px; padding-right: 20px; min-height: calc(100vh - 35px);}
.loginform .title{font-size: 25px;}
.loginform .title span{font-size: 35px;}
.cllcimg{flex: inherit; width: 100%; margin-bottom: 10px;}
}

@media (max-width:414px) {
.studentcert .ctemp .backgroundblur iframe, .iframe {min-height: inherit;}    
.btngrouprht a:not(:first-child), .btngrouprht button:not(:first-child) {margin-left: 5px;}
.btn{padding: 0px 10px; font-size: 12px}
.btn-icon .eva{width: 20px;}
.resbtn .btn {margin: 0px auto 15px !important;display: block;}
.issuecerttabs .nav-pills .nav-item .nav-link{ font-size: 11px; padding: 0px 20px;}
.issuecerttabs .nav-pills .nav-item:not(:first-child) .nav-link{ padding: 0px 15px 0px 35px;}
.albox-btn .btngrouprht {flex-direction: column;display: flex;align-items: center;justify-content: center;width: 100%;}
.albox-btn .btngrouprht .btn, .abunobt .btngrouprht .btn{ margin: 0px 0px 15px 0px;}    
.loginform .form-group label, .loginform a{ font-size: 12px;}   
.cs-mrhtsidebar.show .tableblur{ width: 235px;}
.nav-tabs .nav-link {padding: 15px 5px;font-size: 10px;}
body{ font-size: 12px;}
.mobnav .cmpname {width: 200px;}
.mobnav .cmpname h5 {font-size: 16px;}
.altem{left: -83px;}
}